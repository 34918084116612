import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import axios from "axios";

const StackedBarChart = (props) => {
  const {
    filterBy,
    setfilterBy,
    setselectedDate,
    data,
    autoselectedDate,
    user_id,
    setaudioData,
    fixedData,
    ChartNo,
    setsendingAudioReq,
  } = props;

  const tickAmounts =
    filterBy === "hourly"
      ? 12
      : filterBy === "daily"
      ? 24
      : filterBy === "weekly" && 7;

  const firstTime = autoselectedDate;

  const xCategories = [];
  const uniqueLabels = [...new Set(data.map((item) => item.label))];
  const countsByLabel = {};

  uniqueLabels.forEach((label) => {
    countsByLabel[label] = [];
  });

  for (let i = 0; i < tickAmounts; i++) {
    const hourTime = moment(firstTime).add(i, "hours").format("HH:mm");
    const mintTime = moment(firstTime)
      .add(i * 5, "minutes")
      .format("HH:mm");
    const weekTime = moment(firstTime).add(i, "days").format("MMM D | ddd");
    const formattedHour =
      filterBy === "hourly"
        ? mintTime
        : filterBy === "daily"
        ? hourTime
        : filterBy === "weekly" && weekTime;

    const filteredData = data.filter((item) =>
      moment(item.time_interval)
        .format(
          filterBy === "hourly"
            ? "HH:mm"
            : filterBy === "daily"
            ? "HH:mm"
            : filterBy === "weekly"
            ? "MMM D | ddd"
            : ""
        )
        .includes(formattedHour)
    );

    xCategories.push(formattedHour);

    uniqueLabels.forEach((label) => {
      const labelCount = filteredData
        .filter((item) => item.label === label)
        .reduce((total, item) => total + item.count, 0);

      countsByLabel[label].push(labelCount);
    });
  }

  const series = uniqueLabels.map((label) => ({
    name: label,
    data: countsByLabel[label],
    // color:
    //   ChartNo == 1
    //     ? label === "severe"
    //       ? "#D92429"
    //       : "#FFB500"
    //     : ChartNo == 2 && label === "wet"
    //     ? "#F5C3CB"
    //     : "#8BCDF3",
    color:
      ChartNo == 1
        ? label === "high"
          ? "#f65054e3"
          : "#8BCDF3"
        : ChartNo == 2 && label === "wet"
        ? "#ebc540ab"
        : // : "#FFB500"
          "#a0c582",
  }));

  const handleDataPointSelection = (dataPointIndex) => {
    if (filterBy === "weekly") {
      setselectedDate(
        fixedData.find(
          (item) =>
            moment(item.time_interval).format("MMM D | ddd") ===
            xCategories[dataPointIndex]
        ).time_interval
      );
      setfilterBy("daily");
    } else if (filterBy === "daily") {
      setselectedDate(
        fixedData.filter((item) =>
          item.time_interval.includes(xCategories[dataPointIndex])
        )[0].time_interval
      );
      setfilterBy("hourly");
    } else if (filterBy === "hourly") {
      setsendingAudioReq(true);
      const dateFromIndex = fixedData.filter((item) =>
        item.time_interval.includes(xCategories[dataPointIndex])
      )[0].time_interval;
      const apiUrl = `/portal/recording/${user_id}?hour_filter=${dateFromIndex}&time_slot=${"min"}`;
      axios
        .get(apiUrl)
        .then((res) => {
          setsendingAudioReq(false);
          setaudioData(res.data);
        })
        .catch((error) => {
          console.error("Recordings request failed:", error);
        });
    }
  };

  const options = {
    chart: {
      type: "bar",
      height: 450,
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, config, xCategories) => {
          handleDataPointSelection(config.dataPointIndex, xCategories);
        },
      },
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 0,
              columnWidth: "10",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        columnWidth: "20",
        // borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",
      },
    },
    xaxis: {
      categories: xCategories,
      title: {
        text:
          filterBy == "hourly"
            ? `${moment(autoselectedDate).format("MMM DD, YYYY  HH:mm a")}`
            : filterBy == "daily"
            ? `${moment(autoselectedDate).format("MMM DD ,YYYY")}`
            : `Week ${
                moment(autoselectedDate, "YYYY-MM-DD").week() -
                moment(autoselectedDate, "YYYY-MM-DD").startOf("month").week() +
                1
              } of ${moment(autoselectedDate).format("MMM, YYYY")}`,
        style: {
          color: "#1470A4",
          fontSize: "16px",
          fontFamily: "Noto_Sans",
          fontWeight: 600,
          letterSpacing: "0.1px",
        },
      },
    },
    yaxis: {
      title: {
        text: "No. of Coughs",
        style: {
          color: "#1470A4",
          fontSize: "16px",
          fontFamily: "Noto_Sans",
          fontWeight: 600,
          letterSpacing: "0.1px",
        },
      },
    },
    // title: { text: "Cough Loudness", align: "left", offsetY: 43 },
    legend: {
      position: "top",
      showForSingleSeries: true,
      horizontalAlign: "right",
      markers: {
        radius: 10,
        offsetY: 2,
        width: 33,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div id="chart" data-tut={ChartNo === 1 ? "intensity-plots" : "type-plots"}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default StackedBarChart;

// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import GetData from "../../API/GetData";
// import moment from "moment";

// const StackedBarChart = (props) => {
//   const { filterBy, setfilterBy, setselectedDate, data } = props;

//   const formattedData = data.map((item) => ({
//     x: new Date(item.time_interval),
//     y: item.count,
//     rec_type: item.label,
//   }));

//   const series = [];

//   const uniqueRecTypes = [
//     ...new Set(formattedData.map((item) => item.rec_type)),
//   ];

//   uniqueRecTypes.forEach((rec_type) => {
//     const trace = {
//       name: rec_type,
//       data: [],
//       color: rec_type === "wet" ? "#F5C3CB" : "#8BCDF3",
//     };

//     const filteredData = formattedData.filter(
//       (item) => item.rec_type === rec_type
//     );

//     trace.data = filteredData.map((item) => ({
//       x: item.x.getTime(),
//       y: item.y,
//     }));

//     series.push(trace);
//   });

//   let xCategories = formattedData.map((item) => item.x.getTime());
//   let tickFormat = "HH:mm";

//   if (formattedData.length > 0) {
//     if (filterBy === "hourly") {
//       const startTime = formattedData[0].x.getTime();
//       const endTime = formattedData[formattedData.length - 1].x.getTime();
//       xCategories = getHourlyTicks(startTime, endTime, 12);
//       tickFormat = "HH:mm";
//     } else if (filterBy === "daily") {
//       const dayStartDate = new Date(formattedData[0].x);
//       const dayEndDate = new Date(
//         formattedData[formattedData.length - 1].x.getTime() +
//           24 * 60 * 60 * 1000
//       );
//       xCategories = getDayHours(dayStartDate, dayEndDate);
//       tickFormat = "HH:mm";
//     } else if (filterBy === "weekly") {
//       const weekStartDate = new Date(formattedData[0].x);
//       const weekEndDate = new Date(
//         formattedData[formattedData.length - 1].x.getTime() +
//           7 * 24 * 60 * 60 * 1000
//       );
//       xCategories = getWeekDates(weekStartDate, weekEndDate);
//       tickFormat = "dd/MM";
//     }
//   } else {
//     tickFormat = "dd/MM";
//   }
//   const options = {
//     chart: {
//       height: 350,
//       width: "100%",
//       type: "bar",
//       toolbar: {
//         show: true,
//       },
//       stacked: true,
//       events: {
//         dataPointSelection: (event, chartContext, config, filterBy) => {
//           handleDataPointSelection(config.dataPointIndex, filterBy);
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: 20,
//         dataLabels: {
//           position: "top",
//         },
//       },
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: true,
//       showForNullSeries: true,
//       showForZeroSeries: true,
//       position: "top",
//       offsetY: -30,
//       horizontalAlign: "right",
//       fontSize: "12px",
//       fontFamily: "Poppins",
//       fontWeight: 400,
//       markers: {
//         radius: 10,
//         offsetY: 2,
//       },
//     },
//     tooltip: {
//       enabled: true,
//     },
//     xaxis: {
//       categories: xCategories,
//       labels: {
//         show: true,
//       },
//       title: {
//         text: "Time",
//       },
//       type: "datetime",
//       tickAmount: filterBy === "hourly" ? 12 : null,
//       tickFormat: tickFormat,
//     },
//     yaxis: {
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       title: {
//         text: "No. of Coughs",
//       },
//     },
//   };

//   const handleDataPointSelection = (dataPointIndex) => {
//     setselectedDate(
//       moment(formattedData[dataPointIndex].x).format("YYYY-MM-DDTHH:mm:ss")
//     );
//     setfilterBy(
//       filterBy == "weekly" ? "daily" : filterBy == "daily" ? "hourly" : filterBy
//     );
//   };

//   return (
//     <div id="chart">
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="bar"
//         height={350}
//       />
//     </div>
//   );
// };

// const getHourlyTicks = (startTime, endTime, numberOfTicks) => {
//   const ticks = [];
//   const tickInterval = (endTime - startTime) / (numberOfTicks - 1);

//   for (let i = 0; i < numberOfTicks; i++) {
//     const tickTime = startTime + i * tickInterval;
//     ticks.push(tickTime);
//   }

//   return ticks;
// };

// const getDayHours = (start, end) => {
//   const dayHours = [];
//   const current = new Date(start);

//   while (current <= end) {
//     dayHours.push(current.getTime());
//     current.setHours(current.getHours() + 1);
//   }

//   return dayHours;
// };

// const getWeekDates = (start, end) => {
//   const weekDates = [];
//   const current = new Date(start);
//   while (current <= end) {
//     weekDates.push(current.getTime());
//     current.setDate(current.getDate() + 1);
//   }
//   return weekDates;
// };

// export default StackedBarChart;
