import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as getFirebaseToken,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbOiQs05HuEdDaLrpApm-2aUq8ZoQHiw8",
  authDomain: "cough-tracking.firebaseapp.com",
  projectId: "cough-tracking",
  storageBucket: "cough-tracking.appspot.com",
  messagingSenderId: "163015622468",
  appId: "1:163015622468:web:fdd87a0f003eefd4c7a111",
  measurementId: "G-TBJQJQPY3D",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = (setTokenFound, setFCMtoken) => {
  return getFirebaseToken(messaging, {
    vapidKey:
      "BGqRr0JUkS2ILetWLXKhzNvx1sqoy4aMaZBTbqlm65uYlXEQDrDcSpCoYZCw7EvHdaFIPqkYk8bbYZ_YKN8uBb0",
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        setFCMtoken(currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
