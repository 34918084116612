import * as React from "react";
import Modal from "@mui/material/Modal";
import Img3 from "../../Assets/Images/modalImg.svg";
import Img4 from "../../Assets/Images/patientRespondModal.svg";
import { useState } from "react";
import HomeNotificationsCard from "./HomeNotificationsCard";
import { Alert, CircularProgress, IconButton, Snackbar } from "@mui/material";
import { Close, PreviewOutlined, Restore } from "@mui/icons-material";
import RespondToPatientTabs from "./RespondToPatientTabs";
import No_Notifications from "../../Assets/Images/NoDataIcons/No_Notifications.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import GetData from "../../API/GetData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  // width: "1000px",
  boxShadow: 24,
  padding: 5,
  borderRadius: "8px",
};

const style_sm = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "85%",
  boxShadow: 24,
  borderRadius: "8px",
};

export default function ModalComponent(props) {
  const {
    setOpenModal,
    openModal,
    handleCloseModal,
    handleNotiCloseModal,
    openNotifications,
    setOpenResToPat,
    openResToPat,
    handleResToPatCloseModal,
    patDetail,
    allNotifications,
    setrefreshTable,
    refreshTable,
    setfilterBy,
    setselectedDate,
  } = props;

  const history = useNavigate();
  const [patientName, setpatientName] = useState("");
  const [MedicalCondition, setMedicalCondition] = useState("");
  const [purpose, setpurpose] = useState(
    `Welcome to Dr. ${localStorage.getItem(
      "name"
    )} Clinic - Your Registration Details`
  );
  const [email, setEmail] = useState("");
  const [sendingReq, setSendingReq] = useState(false);
  const [message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [patientNameError, setpatientNameError] = useState("");
  const [medCondError, setmedCondError] = useState("");
  const [purposeError, setpurposeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [docAdvice, setdocAdvice] = useState("");
  const [appointmentDate, setappointmentDate] = useState("");
  const [appointmentDateError, setappointmentDateError] = useState("");
  const momentDate = moment().format("MMM DD,YYYY");
  const [emailtextError, setemailtextError] = useState("");
  const body_text = `I'm pleased to share that you're now a registered patient under Dr. ${localStorage.getItem(
    "name"
  )}. To manage your respiratory health, please monitor and log your cough patterns using the CoughLog mobile app. Instructions are provided below.\nBest wishes, Dr. ${localStorage.getItem(
    "name"
  )} ${momentDate}`;
  const [emailtext, setEmailtext] = useState(body_text);

  const handlePreview = () => {
    setpatientNameError("");
    setmedCondError("");
    setEmailError("");
    setpurposeError("");
    setemailtextError("");

    let isValid = true;

    if (patientName.trim() === "") {
      setpatientNameError("Patient name is required");
      isValid = false;
    }

    if (MedicalCondition.trim() === "") {
      setmedCondError("Medical Condition is required");
      isValid = false;
    }

    if (purpose.trim() === "") {
      setpurposeError("Purpose is required");
      isValid = false;
    }

    if (emailtext.trim() === "") {
      setemailtextError("Email body is required else send default email");
      isValid = false;
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }

    if (isValid) {
      window.open(
        `previewEmail/${patientName}-ys-${emailtext}-ys-${localStorage.getItem(
          "name"
        )}-ys-${purpose}`,
        "_blank"
      );
    }
  };

  const handleSubmit = async (e) => {
    setSendingReq(true);
    e.preventDefault();

    setpatientNameError("");
    setmedCondError("");
    setEmailError("");
    setpurposeError("");
    setemailtextError("");

    let isValid = true;

    if (patientName.trim() === "") {
      setpatientNameError("Patient name is required");
      isValid = false;
    }

    if (MedicalCondition.trim() === "") {
      setmedCondError("Medical Condition is required");
      isValid = false;
    }

    if (purpose.trim() === "") {
      setpurposeError("Purpose is required");
      isValid = false;
    }

    if (emailtext.trim() === "") {
      setemailtextError("Email body is required else send default email");
      isValid = false;
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }

    if (isValid) {
      const formData = {
        name: patientName,
        medical_condition: MedicalCondition,
        email,
        purpose_of_using: purpose,
        email_body: emailtext,
      };

      const res = await GetData.AddPatient(formData);

      if (res.error) {
        setErrorMessage(`Error: ${res.error}`);
      } else {
        setMessage(res.data.detail);
        setEmail("");
        setpurpose("");
        setMedicalCondition("");
        setpatientName("");
        setrefreshTable(!refreshTable);
        setOpenModal(false);
      }
    }
    setSendingReq(false);
  };

  const handleRespondToPatient = async (e) => {
    setSendingReq(true);
    e.preventDefault();
    if (docAdvice || appointmentDate) {
      const formData = {
        msg: docAdvice,
        schedule: appointmentDate
          ? appointmentDate.format("YYYY-MM-DDTHH:mm:ss")
          : null,
        user_id: patDetail.user_id,
      };

      console.log(formData, "formData");

      const res = await GetData.AddAppointment(formData);

      if (res.error) {
        setErrorMessage(`Error: ${res.error}`);
      } else {
        setMessage(res.data.detail);
        setdocAdvice("");
        setappointmentDate("");
        setOpenResToPat(false);
      }

      setSendingReq(false);
    } else {
      setErrorMessage(`Error: Fields are empty`);
      setSendingReq(false);
    }
  };

  const handleCopy = (text) => {
    setMessage(`Copied:  ${localStorage.getItem("invicd")}`);
  };

  return (
    <>
      {openNotifications && (
        <Modal
          open={openNotifications}
          onClose={handleNotiCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // className="my-5"
        >
          <div className="container bg-light" style={style_sm}>
            <p className="position-absolute end-0 top-0">
              <IconButton onClick={handleNotiCloseModal}>
                <Close />
              </IconButton>
            </p>
            <h1 className="p-2">Notifications</h1>
            <div className="h-95 overflow-scroll">
              {Object.keys(allNotifications).length > 0 ? (
                Object.keys(allNotifications).map((date) => (
                  <div key={date}>
                    <p className="fs-10 fw-600 text-primry">
                      {moment().format("ddd, MMM D") ===
                      moment(date).format("ddd, MMM D")
                        ? "Today"
                        : moment().subtract(1, "days").format("ddd, MMM D") ===
                          moment(date).format("ddd, MMM D")
                        ? "Yesterday"
                        : moment(date).format("ddd, MMM D")}
                    </p>
                    {allNotifications[date]?.map((notification) => (
                      <HomeNotificationsCard
                        key={notification.user_id}
                        user_id={notification.user_id}
                        p_name={notification.p_name}
                        count={notification.count}
                        label_value={notification.label_value}
                        time_span={notification.time_span}
                        cough_threshold={notification.cough_threshold}
                        setfilterBy={setfilterBy}
                        date={notification.notification_date}
                        setselectedDate={setselectedDate}
                      />
                    ))}
                  </div>
                ))
              ) : (
                <div className="text-center d-flex h-100 flex-column transition-loading">
                  <div className="my-auto">
                    <img src={No_Notifications} className="" />
                    <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                      No Notification Available
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
      {openModal && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="bg-light col-12 col-md-10 col-lg-7" style={style}>
            <div className="row">
              <p className="text-end">
                <IconButton onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </p>
              <div
                className="row container mx-auto"
                style={{ height: "640px" }}
              >
                <div className="col-md-6 d-none d-lg-block">
                  <div className="h-100 d-flex mx-auto my-auto">
                    <img
                      src={Img3}
                      // width={300}
                      // height={300}
                      className="my-auto mx-5"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 h-100 overflow-auto">
                  <div className="h-100 w-100 d-flex">
                    <form
                      className="mx-auto my-auto w-100 w-md-75"
                      type="submit"
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                        Add New Patient (Send invitation by email)
                        {/* <span className="text-primry fs-14">
                          (My Invitation Code :
                          <CopyToClipboard
                            text={localStorage.getItem("invicd")}
                            onCopy={handleCopy}
                          >
                            <span> {localStorage.getItem("invicd")}</span>
                          </CopyToClipboard>
                          )
                        </span> */}
                      </p>

                      <div className="text-start mb-4">
                        <input
                          className="mb-1"
                          value={patientName}
                          type="text"
                          name="name"
                          onChange={(e) => setpatientName(e.target.value)}
                          placeholder="Patient Name"
                        />
                        {patientNameError && (
                          <div className="invalid-feedback d-block px-2 fw-bold">
                            {patientNameError}
                          </div>
                        )}
                      </div>
                      <div className="text-start mb-4">
                        <input
                          className="mb-1"
                          value={MedicalCondition}
                          type="text"
                          name="MedicalCondition"
                          onChange={(e) => setMedicalCondition(e.target.value)}
                          placeholder="Medical Condition (e.g. Asthma, COPD)"
                        />
                        {medCondError && (
                          <div className="invalid-feedback d-block px-2 fw-bold">
                            {medCondError}
                          </div>
                        )}
                      </div>
                      <div className="text-start mb-4">
                        <input
                          className="mb-1"
                          value={purpose}
                          type="text"
                          name="purpose"
                          onChange={(e) => setpurpose(e.target.value)}
                          placeholder="Subject"
                        />
                        {purposeError && (
                          <div className="invalid-feedback d-block px-2 fw-bold">
                            {purposeError}
                          </div>
                        )}
                      </div>
                      <div className="text-start mb-4">
                        <input
                          className="mb-1"
                          value={email}
                          type="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email Address"
                        />
                        {emailError && (
                          <div className="invalid-feedback d-block px-2 fw-bold">
                            {emailError}
                          </div>
                        )}
                      </div>
                      <div className="text-start mb-4">
                        <textarea
                          className="mb-1"
                          value={emailtext}
                          type="text"
                          name="emailtext"
                          onChange={(e) => setEmailtext(e.target.value)}
                          placeholder="Email text"
                        />
                        {emailtextError && (
                          <div className="invalid-feedback d-block px-2 fw-bold">
                            {emailtextError}{" "}
                            <Restore
                              className="text-primry"
                              onClick={() => setEmailtext(body_text)}
                            />
                          </div>
                        )}
                        <div className="d-inline-flex w-100">
                          <div className="px-2 fw-bold">
                            Customize email body text here
                          </div>
                          <span
                            onClick={handlePreview}
                            className="ms-auto px-2 text-decoration-underline text-primry fw-bold"
                          >
                            Preview
                          </span>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 h-45"
                      >
                        {sendingReq ? (
                          <CircularProgress color="inherit" size={22} />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {openResToPat && (
        <Modal
          open={openResToPat}
          onClose={handleResToPatCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="bg-light col-12 col-lg-7" style={style}>
            <div className="row">
              <p className="text-end">
                <IconButton onClick={handleResToPatCloseModal}>
                  <Close />
                </IconButton>
              </p>
              <div
                className="row container mx-auto"
                style={{ height: "640px" }}
              >
                <div className="d-none d-lg-block col-6">
                  <div className="h-100 d-flex mx-auto my-auto">
                    <img
                      src={Img4}
                      // width={400}
                      // height={400}
                      className="my-auto mx-5 img-fil"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <RespondToPatientTabs
                    setappointmentDate={setappointmentDate}
                    appointmentDate={appointmentDate}
                    appointmentDateError={appointmentDateError}
                    sendingReq={sendingReq}
                    setdocAdvice={setdocAdvice}
                    docAdvice={docAdvice}
                    handleRespondToPatient={handleRespondToPatient}
                    user_id={patDetail.user_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message ? true : ErrorMessage ? true : false}
        onClose={() => {
          setMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {message || ErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
