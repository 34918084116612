import * as React from "react";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function LoginSignupTabs(props) {
  const {
    email,
    password,
    sendingReq,
    errorMessage,
    isrememberme,
    showPassword,
    setpassword,
    setsendingReq,
    seterrorMessage,
    setisrememberme,
    setshowPassword,
    handleSubmit,
    setemail,
    FCMtoken,
    formData,
    setFormData,
  } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="secondary-bg" id="top">
      <Box className="container px-0 px-md-4" sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="mx-auto"
          sx={{ ml: "5vw", fontSize: "16px", fontWeight: 700, width: 200 }}
        >
          <Tab
            className="tab-buttons fw-900 fs-16 Noto_Sans text-center"
            label="Login"
          />
          <Tab
            className="tab-buttons fw-900 fs-16 Noto_Sans text-center"
            label="SignUp"
          />
        </Tabs>
        <Box>
          <TabPanel value={value} index={0}>
            <LoginForm
              email={email}
              password={password}
              sendingReq={sendingReq}
              errorMessage={errorMessage}
              isrememberme={isrememberme}
              showPassword={showPassword}
              setpassword={setpassword}
              setsendingReq={setsendingReq}
              seterrorMessage={seterrorMessage}
              setisrememberme={setisrememberme}
              setshowPassword={setshowPassword}
              handleSubmit={handleSubmit}
              setemail={setemail}
              FCMtoken={FCMtoken}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SignUpForm formData={formData} setFormData={setFormData} />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
}
