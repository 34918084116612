import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import logoImg from "../../Assets/Images/logo.svg";
import { useState } from "react";
import { CardMedia } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Logout, Stop, PlayArrowOutlined } from "@mui/icons-material";

// Array of pages for navigation
const pages = [
  { name: "Dashboard", link: "Dashboard" },
  { name: "My Profile", link: "Profile" },
  { name: "Define Rules", link: "Rules" },
];
// Array of settings for user menu
const settings = [
  { name: "Dashboard", link: "Dashboard" },
  { name: "My Profile", link: "Profile" },
  { name: "Define Rules", link: "Rules" },
];

function Navbar(props) {
  const { setOpenNotifications, setTourStart, tourStart } = props;
  const history = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  // Handlers for opening navigation menu and user menu
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Handlers for closing navigation menu and user menu
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#FFFFFF" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo */}
          <Link to="/Dashboard">
            <CardMedia
              component="img"
              image={logoImg}
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
                width: 34,
                height: 38,
              }}
            />
          </Link>
          {/* Navigation Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="dark"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", color: "dark" },
              }}
            >
              {/* Iterate through pages and create menu items */}
              {pages.map((each) => (
                <MenuItem key={each} onClick={handleCloseNavMenu}>
                  <Link to={each.link}>
                    <Typography
                      textAlign="center"
                      sx={{
                        color: "#187FBC",
                        fontSize: "14px",
                        fontFamily: "Noto_Sans",
                        fontWeight: 700,
                      }}
                    >
                      {each.name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Logo for mobile */}
          <CardMedia
            component="img"
            image={logoImg}
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 1,
              width: 34,
              height: 38,
            }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#252733",
              textDecoration: "none",
            }}
          ></Typography>
          {/* User menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((each) => (
              <Link to={each.link}>
                <Typography
                  data-tut={each.link}
                  textAlign="center"
                  sx={{
                    color: "#187FBC",
                    fontSize: "14px",
                    ml: 3,
                    fontFamily: "Noto_Sans",
                    fontWeight: 600,
                  }}
                >
                  {each.name}
                </Typography>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              data-toggle="tooltip"
              title="Open Setings"
            >
              <Avatar alt={localStorage.getItem("name") || "?"} src="" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* Links to settings */}
              {/* Commented out for now */}
              {/* {settings.map((each) => (
                <Link to={each.link}>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      textAlign="start"
                      sx={{ color: "#187FBC", fontSize: "14px" }}
                    >
                      {each.name}
                    </Typography>
                  </MenuItem>
                </Link>
              ))} */}
              <MenuItem
                className="d-block d-md-none"
                onClick={() => {
                  handleCloseUserMenu();
                  setOpenNotifications(true);
                }}
              >
                <Typography
                  textAlign="start"
                  sx={{ color: "#187FBC", fontSize: "14px" }}
                >
                  Notifications
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setTourStart(!tourStart);
                }}
              >
                <Typography
                  textAlign="start"
                  sx={{
                    color: "#187FBC",
                    fontSize: "14px",
                  }}
                >
                  {tourStart ? (
                    <>
                      <Stop className="fs-2 text-primry" /> End Tour
                    </>
                  ) : (
                    <>
                      <PlayArrowOutlined className="fs-2 text-primry" /> Start
                      Tour
                    </>
                  )}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  localStorage.clear();
                  window.location.reload();
                  history("/login");
                }}
              >
                <Typography
                  textAlign="start"
                  sx={{ color: "#187FBC", fontSize: "14px" }}
                >
                  <Logout className="fs-2 text-primry" /> Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
