import React from "react";
import GetData from "../../API/GetData";
import TableComponent from "./TableComponent";
import { useEffect, useState } from "react";
import { ErrorOutline, Delete, RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  Alert,
  Chip,
  Dialog,
  DialogActions,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { Modal } from "bootstrap";
import moment from "moment";

export default function HomeTableCard(props) {
  // Destructuring props
  const {
    handleOpenModal,
    setOpenModal,
    openModal,
    setpatientData,
    setrefreshTable,
    refreshTable,
  } = props;
  // State initialization
  const [allPatientsList, setallPatientsList] = useState([]);
  const [page, setpage] = useState(1);
  const [size, setsize] = useState(100);
  const [Message, setMessage] = useState(false);
  const [deletableUser, setdeletableUser] = useState(null);
  const [copiedText, setCopiedText] = useState("");
  const [openalert, setOpenAlert] = useState(false);

  // useEffect to fetch all patients list when component mounts or refreshTable changes
  useEffect(() => {
    GetData.AllPatientsList(page, size).then((res) => {
      res = res.data.data;
      for (let i in res) {
        res[i].sNo = Number(i) + 1;
      }
      setallPatientsList(res);
    });
  }, [refreshTable]);

  // Function to format date
  function fullDate(params) {
    if (params.value) {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    } else return "-";
  }

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   minWidth: 400,
  //   width: "1000px",
  //   boxShadow: 24,
  //   p: 5,
  //   borderRadius: "8px",
  // };

  // Function to handle patient deletion
  const DeletePatient = (deletableUser) => {
    const user_id = deletableUser.data.user_id;
    const name = deletableUser.data.name;
    if (user_id) {
      const res = GetData.PostDeletePatient(user_id);
      res.then((value) => {
        console.log(value);
        setMessage(
          `Patient ${name} has been removed successfully from your List`
        );
        setOpen(false);
        setrefreshTable(!refreshTable);
        setdeletableUser(null);
      });
    }
  };

  // Handlers for dialog opening and closing
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setdeletableUser(null);
  };

  // Column definitions for the patient table
  const columnDefs = [
    {
      headerName: "Sr. No",
      field: "sNo",
      sortable: true,
      minWidth: 50,
      maxWidth: 85,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      minWidth: 120,
    },
    {
      headerName: "Coughs Recorded",
      field: "no_cough",
      sortable: true,
      minWidth: 80,
    },
    {
      headerName: "Hours Tracked",
      field: "hour_tracked",
      sortable: true,
      minWidth: 80,
    },
    {
      headerName: "Registered On",
      field: "register",
      cellRenderer: fullDate,
      sortable: true,
      minWidth: 120,
    },
    {
      headerName: "Appointments",
      field: "appointment_date",
      cellRenderer: (params) =>
        params.value ? moment(params.value).format("DD-MMM-YY HH:mm a") : "-",
      sortable: true,
      minWidth: 100,
    },
    {
      headerName: "App Status",
      field: "is_active",
      cellRenderer: (params) =>
        params.value ? (
          <p className="fs-14 Noto_Sans text-success">Active</p>
        ) : (
          <p className="fs-14 Noto_Sans text-warning">Pending...</p>
        ),
      sortable: true,
      minWidth: 80,
      maxWidth: 110,
    },
    // {
    //   headerName: "Severity Level",
    //   field: "name",
    //   sortable: true,
    //   minWidth: 80,
    // },
    // {
    //   headerName: "Status",
    //   field: "name",
    //   sortable: true,
    //   minWidth: 80,
    //   cellRenderer: function (params) {
    //     return (
    //       <Chip label={params.value} variant="filled" className="fs-10 px-3" />
    //     );
    //   },
    // },
    {
      headerName: "Action",
      field: "sNo",
      sortable: true,
      minWidth: 70,
      cellRenderer: function (params) {
        return (
          <Stack direction="row" spacing={2}>
            <Link
              to={params.data.is_active && `/detail/${params.data.user_id}`}
              className={`${
                params.data.is_active
                  ? "text-primry"
                  : "text-secondary opacity-50"
              }`}
            >
              <IconButton
                data-toggle="tooltip"
                title={`${
                  params.data.is_active
                    ? "view details"
                    : "user is in-active on the app"
                }`}
              >
                <RemoveRedEye className="mx-auto fs-1 text-primry" />
              </IconButton>
            </Link>
            <IconButton data-toggle="tooltip" title="Delete patient">
              <Delete
                onClick={() => {
                  handleClickOpen();
                  setdeletableUser(params);
                }}
                className="mx-auto fs-1 text-danger"
              />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      {/* Snackbar for displaying success message */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Message ? true : false}
        onClose={() => {
          setMessage("");
        }}
      >
        <Alert
          severity="success"
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {Message}
        </Alert>
      </Snackbar>
      {/* Dialog for confirming patient deletion */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="col-12 p-5">
          <p className="fs-16 fw-400 Noto_Sans text-center d-inline-flex w-100">
            <ErrorOutline className="fs-1 my-auto me-4 text-warning" /> You want
            to delete user
            <span className="mx-2 fw-600">{deletableUser?.data.name}</span>?
          </p>
          <div className="d-inline-flex w-100 pt-5">
            <button
              className="btn text-primry btn-light brdr-primry Noto_Sans fw-600 fs-14 w-25 ms-auto h-32"
              onClick={handleClose}
            >
              Disagree
            </button>
            <button
              className="btn btn-danger Noto_Sans text-light ms-3 fw-600 fs-14 w-25 h-32"
              onClick={() => DeletePatient(deletableUser)}
            >
              Agree
            </button>
          </div>
        </div>
      </Dialog>
      {/* TableComponent to render the patients table */}
      <TableComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleOpenModal={handleOpenModal}
        value={"allPatientsList"}
        Data={allPatientsList}
        columnDefs={columnDefs}
        floatingFilter={true}
        copiedText={copiedText}
        setCopiedText={setCopiedText}
        openalert={openalert}
        setOpenAlert={setOpenAlert}
      />
    </>
  );
}
