import React, { useState, useEffect } from "react";
import Img1 from "../../Assets/Images/login_img.svg";
import Img2 from "../../Assets/Images/CoughLoglogo.svg";
import footerLogo from "../../Assets/Images/ai4lyfLogo.svg";
import { useNavigate } from "react-router-dom";
import Account from "../../API/Account";
import Auth from "../../Middleware/Auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import LoginSignupTabs from "../../Atoms/CustomComponets/LoginSignupTabs.js";
import { getToken, onMessageListener } from "../../firebase";

export default function Index() {
  const history = useNavigate();
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [sendingReq, setsendingReq] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isrememberme, setisrememberme] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [phoneNumber, setphoneNumber] = useState("");
  const [gender, setgender] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    dob: "",
    gender: "",
    physicalAddress: "",
  });

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [tokenFound, setTokenFound] = useState(false);
  const [FCMtoken, setFCMtoken] = useState();
  getToken(setTokenFound, setFCMtoken);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const handleSubmit = async (e) => {
    setsendingReq(true);
    e.preventDefault();
    const guid = FCMtoken;
    const res = Account.LoginApi(email, password, guid);
    res.then((value) => {
      setsendingReq(false);
      if (value !== undefined && value.data.token) {
        localStorage.setItem("userIsLoggedIn", true);
        localStorage.setItem("accessToken", value.data.token);
        localStorage.setItem("role", value.data.role);
        localStorage.setItem("name", value.data.name);
        localStorage.setItem("invicd", value.data.invitation_code);
        goToDashboard();
        Auth.Login();
      } else seterrorMessage(true);
    });
  };

  const goToDashboard = () => {
    history("/Dashboard");
    window.location.reload();
  };

  return (
    <div className="row container px-0 px-md-4 mx-auto vh-100">
      <div className="col-12 col-md-6 d-none d-md-block">
        <div className="h-100 d-flex">
          <div className="h-100 d-flex mx-auto my-auto">
            <img src={Img1} className="img-fill" />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="vh100-login mx-auto col-lg-8 my-auto">
          <div className="d-flex w-100 mx-auto">
            <img src={Img2} className="mx-auto" />
          </div>
          <LoginSignupTabs
            email={email}
            setemail={setemail}
            password={password}
            sendingReq={sendingReq}
            errorMessage={errorMessage}
            isrememberme={isrememberme}
            showPassword={showPassword}
            setphone={setphone}
            setpassword={setpassword}
            setsendingReq={setsendingReq}
            seterrorMessage={seterrorMessage}
            setisrememberme={setisrememberme}
            setshowPassword={setshowPassword}
            phoneNumber={phoneNumber}
            setphoneNumber={setphoneNumber}
            DateOfBirth={DateOfBirth}
            setDateOfBirth={setDateOfBirth}
            gender={gender}
            setgender={setgender}
            handleSubmit={handleSubmit}
            FCMtoken={FCMtoken}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>

      <footer className="container py-0">
        <p className="Noto_Sans fs-16 fw-400 mb-0 text-center">
          <span className="text-primry fw-600 me-2">CoughLog</span>Powered by
          <img src={footerLogo} className="ms-3" />
        </p>
      </footer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessage ? true : false}
        onClose={() => seterrorMessage(false)}
      >
        <Alert
          severity="warning"
          autohideduration={3000}
          sx={{ width: 500, fontSize: "16px" }}
        >
          wrong email or password
        </Alert>
      </Snackbar>
    </div>
  );
}
