import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useLocation } from "react-router-dom";
import { Add, CopyAll } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@mui/base";
import { Alert } from "@mui/material";

export default function TableComponent(props) {
  // Ref for ag-Grid instance
  const gridRef = useRef(null);
  // State variables
  const [gridApi, setGridApi] = useState();
  const [state, setstate] = useState(true);
  const [states, setstates] = useState(true);
  const location = useLocation();

  // useEffect to initialize or update grid data
  useEffect(() => {
    // Update grid data when props.Data changes
    if (gridApi) {
      props.datefilters(gridApi);
      gridApi.api.onFilterChanged();
      setstate(!state);
      gridApi.paginationSetPageSize(5);
      gridApi.setRowData(props.Data);
    }
  }, [props.Data]);

  // ... (handling null values in cells)
  function handleNullValues(params) {
    if (params.value) return params.value;
    else return "-";
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 130,
      sortable: true,
      resizable: true,
      cellRenderer: handleNullValues,
    };
  }, []);

  // Callback for filter text box change
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  // useEffect to handle filter state and saved filter model
  useEffect(() => {
    if (gridApi) {
      document.getElementById("filter-text-box").value = localStorage.getItem(
        `quick_${props.value}`
      );
      gridApi.api.setQuickFilter(" ");
      gridApi.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    }
  }, [gridApi, props.Data]);

  const inputRef = useRef(null);

  const statusBar = useMemo(() => {
    if (!props.count) {
      return {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          { statusPanel: "agTotalRowCountComponent", align: "left" },
          { statusPanel: "agFilteredRowCountComponent", align: "center" },
          { statusPanel: "agSelectedRowCountComponent", align: "center" },
          { statusPanel: "agAggregationComponent", align: "center" },
        ],
      };
    }
  }, []);

  useEffect(() => {
    setstates(!states);
  }, [props.counts]);

  var savedFilterModel = null;
  useEffect(() => {
    if (gridApi) {
      savedFilterModel = gridApi.api.getFilterModel();
      // localStorage.setItem(props.value, JSON.stringify(savedFilterModel));    }
    }
  }, [state]);

  // useEffect to handle filter state and saved filter model
  useEffect(() => {
    if (gridApi) {
      const savedfiltermodel = localStorage.getItem(props.value);
      gridApi.api.setFilterModel(JSON.parse(savedfiltermodel));
      gridApi.api.setQuickFilter("gtrrgrgrg");
      gridApi.api.setQuickFilter(localStorage.getItem(`quick_${props.value}`));
    }
  }, [gridApi, props.Data]);

  return (
    <div className="ticket-cards h-600 grid-table">
      <div className="d-inline-flex w-100">
        <h3 className="my-auto">Patients List</h3>
        <button
          id="add-patient-button"
          className="fs-16 px-3 btn btn-primary ms-auto max-h-40"
          onClick={props.handleOpenModal}
        >
          <Add className="me-2 fs-1" /> Add Patient
        </button>
        <input
          autoComplete="off"
          autoFocus={true}
          ref={inputRef}
          className="form-control w-25 ms-2 max-h-40"
          type="search"
          placeholder="Search..."
          aria-label="Search"
          onInput={onFilterTextBoxChanged}
          id="filter-text-box"
        />
      </div>
      <span className="black-line d-inline-flex w-100 mb-3"></span>
      <div>
        <div className="ag-theme-alpine ag-grid-size">
          <AgGridReact
            ref={gridRef}
            rowData={props.Data}
            columnDefs={props.columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowGroupPanelShow={"always"}
            statusBar={statusBar}
            suppressCsvExport={true}
            suppressExcelExport={true}
          />
        </div>
      </div>
    </div>
  );
}
