import moment from "moment";

const NotificationsData = [
  {
    user_id: 1,
    p_name: "John Doe",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(1, "days").toDate(),
    key: 1,
  },
  {
    user_id: 2,
    p_name: "Jane Smith",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(2, "weeks").toDate(),
    key: 2,
  },
  {
    user_id: 3,
    p_name: "Alice Johnson",
    label_value: "low",
    time_span: "hourly",
    date: moment().subtract(3, "hours").toDate(),
    key: 3,
  },
  // Add more test data below
  {
    user_id: 4,
    p_name: "Bob Williams",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(4, "days").toDate(),
    key: 4,
  },
  {
    user_id: 5,
    p_name: "Eva Brown",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(5, "weeks").toDate(),
    key: 5,
  },
  {
    user_id: 6,
    p_name: "Mike Taylor",
    label_value: "low",
    time_span: "hourly",
    date: moment().subtract(6, "hours").toDate(),
    key: 6,
  },
  {
    user_id: 7,
    p_name: "Sophia Clark",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(7, "days").toDate(),
    key: 7,
  },
  {
    user_id: 8,
    p_name: "Oliver Wilson",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(8, "weeks").toDate(),
    key: 8,
  },
  {
    user_id: 9,
    p_name: "Emma Garcia",
    label_value: "low",
    time_span: "hourly",
    date: moment().subtract(9, "hours").toDate(),
    key: 9,
  },
  {
    user_id: 10,
    p_name: "William Rodriguez",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(10, "days").toDate(),
    key: 10,
  },
  {
    user_id: 11,
    p_name: "Isabella Martinez",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(11, "weeks").toDate(),
    key: 11,
  },
  {
    user_id: 12,
    p_name: "James Anderson",
    label_value: "low",
    time_span: "hourly",
    date: moment().subtract(12, "hours").toDate(),
    key: 12,
  },
  {
    user_id: 13,
    p_name: "Mia Moore",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(13, "days").toDate(),
    key: 13,
  },
  {
    user_id: 14,
    p_name: "Alexander White",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(14, "weeks").toDate(),
    key: 14,
  },
  {
    user_id: 15,
    p_name: "Charlotte Harris",
    label_value: "low",
    time_span: "hourly",
    date: moment().subtract(15, "hours").toDate(),
    key: 15,
  },
  {
    user_id: 16,
    p_name: "Chris Lee",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(16, "days").toDate(),
    key: 16,
  },
  {
    user_id: 17,
    p_name: "David Garcia",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(17, "weeks").toDate(),
    key: 17,
  },
  {
    user_id: 18,
    p_name: "Fiona Martinez",
    label_value: "high",
    time_span: "hourly",
    date: moment().subtract(18, "hours").toDate(),
    key: 18,
  },
  {
    user_id: 19,
    p_name: "Grace Thompson",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(19, "days").toDate(),
    key: 19,
  },
  {
    user_id: 20,
    p_name: "Henry Adams",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(20, "weeks").toDate(),
    key: 20,
  },
  {
    user_id: 21,
    p_name: "Jack Turner",
    label_value: "high",
    time_span: "hourly",
    date: moment().subtract(21, "hours").toDate(),
    key: 21,
  },
  {
    user_id: 22,
    p_name: "Lily Parker",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(22, "days").toDate(),
    key: 22,
  },
  {
    user_id: 23,
    p_name: "Noah Scott",
    label_value: "low",
    time_span: "weekly",
    date: moment().subtract(23, "weeks").toDate(),
    key: 23,
  },
  {
    user_id: 24,
    p_name: "Olivia Campbell",
    label_value: "high",
    time_span: "hourly",
    date: moment().subtract(24, "hours").toDate(),
    key: 24,
  },
  {
    user_id: 25,
    p_name: "Peter Hill",
    label_value: "high",
    time_span: "daily",
    date: moment().subtract(25, "days").toDate(),
    key: 25,
  },
];

export default NotificationsData;
