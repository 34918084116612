import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { useState } from "react";
import ForgetPass from "./ForgetPass";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import Account from "../../API/Account";
import { useNavigate } from "react-router-dom";
import Auth from "../../Middleware/Auth";

export default function LoginForm(props) {
  const {
    email,
    password,
    sendingReq,
    isrememberme,
    showPassword,
    setpassword,
    setisrememberme,
    setshowPassword,
    handleSubmit,
    setemail,
    setsendingReq,
    FCMtoken,
  } = props;

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [OpenforgetPass, setOpenforgetPass] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [GoogleerrorMessage, setGoogleerrorMessage] = useState("");
  const history = useNavigate();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setemail(value);
    setEmailError(validateEmail(value) ? "" : "Invalid email format");
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setpassword(value);
    setPasswordError(
      value.length >= 6 ? "" : "Password must be at least 6 characters"
    );
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const goToDashboard = () => {
    history("/Dashboard");
    window.location.reload();
  };

  return (
    <>
      <div className="mx-auto mb-2 w-35">
        <p className="Noto_Sans fs-36 text-center fw-bold text-grey-black">
          Login to your Account
        </p>
        <p className="text-center fs-16 Noto_Sans text-grey-black">
          See what is going on with your patients
        </p>
      </div>
      <form
        className="w-100"
        type="submit"
        onSubmit={handleSubmit}
        autoomplete="off"
      >
        <div className="text-start mb-4">
          <label htmlFor="email">Email</label>
          <input
            value={email}
            type="email"
            name="email"
            onChange={handleEmailChange}
            placeholder="Email address"
            className="mb-0"
            autocomplete="false"
          />
          {emailError && (
            <div className="invalid-feedback d-block fw-bold">{emailError}</div>
          )}
        </div>
        <div className="position-relative text-start mb-4">
          <label htmlFor="password">Password</label>
          <input
            className="mb-0"
            value={password}
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={handlePasswordChange}
            placeholder="Password"
          />
          {passwordError && (
            <div className="invalid-feedback d-block fw-bold">
              {passwordError}
            </div>
          )}
          <div
            className="eye-div bg-scndry"
            onClick={() => setshowPassword(!showPassword)}
          >
            {showPassword ? (
              <VisibilityOff className="d-flex mt-3 mx-auto fs-1 text-blue" />
            ) : (
              <Visibility className="d-flex mt-3 mx-auto fs-1 text-blue" />
            )}
          </div>
        </div>
        <div className="w-100 d-inline-flex mt-2">
          <div className="d-inline-flex w-100 me-auto ps-3">
            <div className="form-check login-check-box">
              <input
                className="form-check-input"
                type="checkbox"
                value={isrememberme}
                name="isrememberme"
                onChange={(e) => setisrememberme(e.target.value)}
              />
              <p className="ps-3 pt-3 text-secondary fs-12 Noto_Sans text-nowrap">
                Remember Me
              </p>
            </div>
          </div>
          <p
            className="Latto fs-12 fw-600 text-primry text-nowrap"
            onClick={() => {
              setOpenforgetPass(true);
            }}
          >
            Forgot Password?
          </p>
        </div>
        <button
          type="submit"
          className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45"
        >
          {sendingReq ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            "Login"
          )}
        </button>
        <div className="row mb-4 disabled">
          <p className="text-center fs-16 fw-bold my-3 text-primry">OR</p>
          <div className="col-12">
            <GoogleLogin
              className="w-100"
              onSuccess={(credentialResponse) => {
                if (credentialResponse.credential != null) {
                  const token = credentialResponse.credential;
                  const guid = FCMtoken;
                  const res = Account.GoogleLoginApi(token, guid);
                  res.then((value) => {
                    setsendingReq(false);
                    if (value !== undefined && value.data.token) {
                      localStorage.setItem("userIsLoggedIn", true);
                      localStorage.setItem("accessToken", value.data.token);
                      localStorage.setItem("role", value.data.role);
                      localStorage.setItem("name", value.data.name);
                      goToDashboard();
                      Auth.Login();
                    } else {
                      seterrorMessage(true);
                      setGoogleerrorMessage(value.data?.detail);
                    }
                  });
                }
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              theme="filled_blue"
              shape="circle"
            />
          </div>
          {/* <button
            onClick={googleLogout()}
            type="button"
            className="btn btn-primary Noto_Sans fs-18 col-md-3 h-75 py-2 text-light fw-800"
          >
            Logout
          </button> */}
        </div>
      </form>
      <ForgetPass
        OpenforgetPass={OpenforgetPass}
        setOpenforgetPass={setOpenforgetPass}
      />
      {GoogleerrorMessage && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={GoogleerrorMessage ? true : false}
          onClose={() => {
            seterrorMessage("");
          }}
        >
          <Alert
            severity={GoogleerrorMessage && "warning"}
            autohideduration={3000}
            sx={{ width: 500, fontSize: "18px" }}
          >
            {console.log(GoogleerrorMessage, "vvv")}
            {GoogleerrorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
