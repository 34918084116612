import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import OtpInput from "react-otp-input";
import {
  Alert,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
} from "@mui/material";
import Account from "../../API/Account";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";

export default function ForgetPass(props) {
  const { OpenforgetPass, setOpenforgetPass } = props;
  const [OpenOtpModel, setOpenOtpModel] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setemail] = useState("");
  const [otp, setOtp] = useState("");
  const [checkotp, setcheckOtp] = useState("");
  const [showotp, setshowOtp] = useState(false);
  const [sendingReq, setsendingReq] = useState("");
  const [message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setshowPassword] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
  };

  const handleOTPSubmit = async (e) => {
    setsendingReq(true);
    e.preventDefault();

    try {
      const res = await Account.ForgetOtp(email);

      if (res.error) {
        setsendingReq(false);
        setErrorMessage(`Error: ${res.error}`);
      } else {
        setsendingReq(false);
        setMessage(res.data.detail);
        setcheckOtp(res.data.otp);
        setshowOtp(true);
        setOpenforgetPass(false);
        setOpenOtpModel(true);
      }
    } catch (error) {
      setsendingReq(false);
      setErrorMessage(`Error: ${error.error}`);
    }
  };

  const handleForgetPassSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setConfirmPasswordError("");
    let isValid = true;

    if (password === "") {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    if (isValid) {
      const formData = {
        email: email,
        new_password: password,
        otp: otp,
      };

      try {
        setsendingReq(true);
        const res = await Account.ForgetPassword(formData);

        if (res.error) {
          setsendingReq(false);
          setErrorMessage(`Error: ${res.error}`);
        } else {
          setsendingReq(false);
          setMessage(res.data.detail);
          setOpenOtpModel(false);
        }
      } catch (error) {
        setsendingReq(false);
        setErrorMessage(`Error: ${error.error}`);
      }
    }
  };
  // const handleForgetPassSubmit = (e) => {
  //   setsendingReq(true);
  //   e.preventDefault();
  //   setPasswordError("");
  //   setConfirmPasswordError("");
  //   let isValid = true;

  //   if (password === "") {
  //     setPasswordError("Password is required");
  //     isValid = false;
  //   } else if (password.length < 6) {
  //     setPasswordError("Password must be at least 6 characters");
  //     isValid = false;
  //   }

  //   if (password !== confirmPassword) {
  //     setConfirmPasswordError("Passwords do not match");
  //     isValid = false;
  //   }
  //   if (isValid) {
  //     const formData = {
  //       email: email,
  //       new_password: password,
  //       otp: otp,
  //     };

  //     const res = Account.ForgetPassword(formData);
  //     res.then((value) => {
  //       console.log(value, "slottt");
  //       setsendingReq(false);
  //       setMessage(value.data.detail);
  //       setOpenOtpModel(false);
  //     });
  //   }
  // };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setemail(value);
    setEmailError(validateEmail(value) ? "" : "Invalid email format");
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateOTP = (otp) => {
    return /^\d{4}$/.test(otp);
  };

  return (
    <>
      <Modal open={OpenOtpModel}>
        <div className="container bg-light" style={style}>
          <p className="text-end">
            <IconButton onClick={() => setOpenOtpModel(false)}>
              <Close />
            </IconButton>
          </p>
          <form
            className="mx-auto my-auto w-100 w-md-75"
            type="submit"
            onSubmit={handleForgetPassSubmit}
            autoComplete="off"
          >
            <div className="text-start mb-3">
              <label htmlFor="email">Email</label>
              <input
                value={email}
                type="email"
                name="email"
                onChange={handleEmailChange}
                placeholder="Email address"
                className="mb-0"
                disabled
              />
              {emailError && (
                <div className="invalid-feedback d-block fw-bold">
                  {emailError}
                </div>
              )}
            </div>
            <div className="text-start mb-3 otp">
              <OtpInput
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                  setOTPError(validateOTP(value) ? "" : "Invalid OTP");
                }}
                numInputs={4}
                renderInput={(props, index) => (
                  <input {...props} key={index} style={{ width: "2rem" }} />
                )}
              />
            </div>
            <div className="position-relative text-start mb-3">
              <input
                value={password}
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e) => setPassword(e.target.value.toString())}
                placeholder="New password"
                className="mb-0"
              />
              {passwordError && (
                <div className="invalid-feedback d-block fw-bold">
                  {passwordError}
                </div>
              )}
              <div
                className="eye-div-2 bg-scndry"
                onClick={() => setshowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOff className="d-flex mt-3 mx-auto fs-1 text-blue" />
                ) : (
                  <Visibility className="d-flex mt-3 mx-auto fs-1 text-blue" />
                )}
              </div>
            </div>
            <div className="position-relative text-start mb-3">
              <input
                value={confirmPassword}
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value.toString())}
                placeholder="Confirm password"
                className="mb-0"
              />
              {confirmPasswordError && (
                <div className="invalid-feedback d-block fw-bold">
                  {confirmPasswordError}
                </div>
              )}
              <div
                className="eye-div-2 bg-scndry"
                onClick={() => setshowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOff className="d-flex mt-3 mx-auto fs-1 text-blue" />
                ) : (
                  <Visibility className="d-flex mt-3 mx-auto fs-1 text-blue" />
                )}
              </div>
            </div>
            <div className="row">
              <button
                type="submit"
                className="btn btn-primary Noto_Sans fs-18 col-4 ms-auto p-3 text-light fw-800"
              >
                {sendingReq ? (
                  <CircularProgress color="inherit" size={22} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={OpenforgetPass}>
        <div className="container bg-light" style={style}>
          <p className="text-end">
            <IconButton onClick={() => setOpenforgetPass(false)}>
              <Close />
            </IconButton>
          </p>
          <form
            className="mx-auto my-auto w-100 w-md-75"
            type="submit"
            onSubmit={handleOTPSubmit}
            autoComplete="off"
          >
            <div className="text-start mb-4">
              <label htmlFor="email">Email</label>
              <input
                value={email}
                type="email"
                name="email"
                required
                onChange={handleEmailChange}
                placeholder="Email address"
                className="mb-0"
              />
              {emailError && (
                <div className="invalid-feedback d-block fw-bold">
                  {emailError}
                </div>
              )}
            </div>
            <div className="row mx-0">
              <button
                type="button"
                onClick={() => {
                  setOpenforgetPass(false);
                  setemail("");
                }}
                className="btn btn-outline-secondary Noto_Sans fs-18 col-5 p-3 fw-800 ms-0"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary Noto_Sans fs-18 col-5 ms-auto p-3 text-light fw-800"
              >
                {sendingReq ? (
                  <CircularProgress color="inherit" size={22} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message ? true : ErrorMessage ? true : false}
        onClose={() => {
          setMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {message || ErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
