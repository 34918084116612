import React, { useState } from "react";
import { Chip, CircularProgress, Paper } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import moment from "moment";
import GetData from "../../API/GetData";
import Joyride from "react-joyride";

export default function AudiosBox(props) {
  const {
    key,
    audio,
    type,
    intensity,
    date_time,
    id,
    notes,
    setMessage,
    setErrorMessage,
    tourStart,
  } = props;

  const initialText = `Cough type is ${type} and its loudness is ${intensity}`;
  const maxDisplayLength = 50;

  const [isExpanded, setIsExpanded] = useState(false);
  const [addnotes, setAddnotes] = useState(false);
  const [sendingReq, setsendingReq] = useState(false);
  const [doc_Note, setdoc_Note] = useState(notes);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded
    ? initialText
    : initialText.slice(0, maxDisplayLength);

  const submitNotes = async (e) => {
    setsendingReq(true);
    e.preventDefault();
    const Payload = { rec_id: id, note: doc_Note };
    try {
      const res = await GetData.PostDocNotes(Payload);

      if (res.error) {
        setsendingReq(false);
        setErrorMessage(`Error: Field ${res.error[0].msg}`);
      } else {
        setsendingReq(false);
        setMessage(res.data.detail);
      }
    } catch (error) {
      setsendingReq(false);
      setErrorMessage(`Error: ${error.error}`);
    }
  };

  const audioTour = [
    {
      target: "#play-audio",
      content: "Doctor can play that particular Recording.",
    },
    {
      target: "#add-notes",
      content:
        "By Clicking Add Notes button Doctor can add comment/note to that particular Recording.",
    },
    {
      target: "#respond-patient",
      content:
        "By Clicking Resspond to patient button user can send a message to the patient as well as set appointment for the patient.",
    },
  ];

  return (
    <div
      className="row audio-card"
      style={{ height: addnotes ? "" : "70px", maxHeight: "110px" }}
    >
      <div className="col-md-6 my-auto">
        <div className="row">
          <audio id="play-audio" controls className="col-7 h-45">
            <source src={audio} type="audio/mpeg" />
          </audio>
          <p className="Noto_Sans fs-14 fw-400 my-auto col-3 text-center">
            {moment(date_time).format("HH:mm , MMM D, YYYY")}
          </p>
          <div className="col-2 my-auto brdr-right-left text-center">
            <Chip
              label={intensity}
              variant="filled"
              className="fs-14 text-capitalize chip-severity"
              style={{
                background: intensity == "low" ? "#8bcdf3" : "#f65054e3",
                color: intensity == "low" ? "#ffff" : "#ffff",
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row ms-1">
          <p
            className={`Noto_Sans fs-14 mt-4 fw-500 ${
              initialText.length > maxDisplayLength ? "col-7" : "col-9"
            }`}
          >
            Notes: {displayText}
          </p>
          {initialText.length > maxDisplayLength && (
            <p
              className="text-decoration-underline fs-14 text-primry col-2 my-auto"
              onClick={toggleExpansion}
            >
              {isExpanded ? "See Less" : "See More"}
            </p>
          )}
          <button
            id="add-notes"
            onClick={() => setAddnotes(!addnotes)}
            className="btn btn-primary Noto_Sans fs-14 text-light ms-auto w-11 h-28 p-0 my-auto"
          >
            {addnotes ? <Remove /> : <Add className="" />} Add notes
          </button>
        </div>
      </div>
      {addnotes && (
        <form
          className="row mt-3 notes-form"
          type="submit"
          onSubmit={submitNotes}
          autoComplete="off"
        >
          <div className="text-start mb-1 col-11">
            <input
              value={doc_Note}
              type="text"
              name="notes"
              onChange={(e) => setdoc_Note(e.target.value)}
              placeholder="MD notes comes here."
            />
          </div>
          <div className="d-inline-flex col-1">
            {/* <button
              className="col-5 btn text-primry btn-light brdr-primry Noto_Sans fs-14 w-80 h-32"
              onClick={() => setAddnotes(false)}
            >
              Cancel
            </button> */}
            <button
              className="col-5 btn btn-primary Noto_Sans text-light ms-auto fs-14 w-80 h-32"
              type="submit"
            >
              {sendingReq ? (
                <CircularProgress color="inherit" size={22} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      )}
      <Joyride
        steps={audioTour}
        run={tourStart}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress={true}
        disableScrollParentFix={true}
      />
    </div>
  );
}
