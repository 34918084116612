import React, { useState } from "react";
import { Alert, CircularProgress, IconButton, Snackbar } from "@mui/material";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "../CustomCss/datePickerStyles.css";
import Account from "../../API/Account";
import { useNavigate } from "react-router-dom";
import {
  Close,
  InfoRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { isValidNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import Modal from "@mui/material/Modal";

export default function SignUpForm() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    dob: "",
    gender: "",
    physicalAddress: "",
  });

  const [errors, setErrors] = useState({});
  const [sendingReq, setSendingReq] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disabledSignupModal, setDisabledSignupModal] = useState(false);

  const history = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (formData.first_name.trim() === "") {
      newErrors.first_name = "First name is required";
    }

    if (formData.last_name.trim() === "") {
      newErrors.last_name = "Last name is required";
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    // if (formData.phone.length > 14 || formData.phone.length < 10) {
    //   newErrors.phone = "10-14 digits required";
    // }

    // if (!isValidNumber(parsePhoneNumberFromString(formData.phone))) {
    //   newErrors.phone = "Invalid phone number";
    // }

    if (formData.password === "") {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = (fieldName) => () => {
    validateForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.physicalAddress) {
      formData.physicalAddress = null;
    }

    if (validateForm()) {
      setSendingReq(true);
      setErrors({});
      formData.confirmPassword = undefined;
      const res = await Account.SignUp(formData);

      if (res.error) {
        setErrors({ serverError: `Error: ${res.error}` });
      } else {
        setMessage(`${res.data.detail} as a ${res.data.role}`);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: "",
          phone: "",
          dob: "",
          gender: "",
          physicalAddress: "",
        });
        history("/Login");
      }
      setSendingReq(false);
    }
  };

  return (
    <>
      <div className="mx-auto mb-2 w-35">
        <p className="Noto_Sans fs-36 text-center fw-bold text-grey-black">
          Sign Up
        </p>
        <p className="text-center fs-16 Noto_Sans text-grey-black">
          It’s quick and easy
        </p>
      </div>
      <form className="w-100 row" autoComplete="off">
        {/* onSubmit={handleSubmit}  */}
        <div className="text-start mb-2 col-6">
          <label htmlFor="first_name" className="text-nowrap">
            First Name
          </label>
          <input
            value={formData.first_name}
            type="text"
            name="first_name"
            onChange={(e) =>
              setFormData({ ...formData, first_name: e.target.value })
            }
            onBlur={handleBlur("first_name")}
            placeholder="John"
            className="mb-0"
          />
          {errors.first_name && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.first_name}
            </div>
          )}
        </div>
        <div className="text-start mb-2 col-6">
          <label htmlFor="last_name" className="text-nowrap">
            Last Name
          </label>
          <input
            value={formData.last_name}
            type="text"
            name="last_name"
            onChange={(e) =>
              setFormData({ ...formData, last_name: e.target.value })
            }
            onBlur={handleBlur("last_name")}
            placeholder="Marsh"
            className="mb-0"
          />
          {errors.last_name && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.last_name}
            </div>
          )}
        </div>
        <div className="text-start mb-2 col-12">
          <label htmlFor="email">Email*</label>
          <input
            value={formData.email}
            type="email"
            name="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            onBlur={handleBlur("email")}
            placeholder="user@mail.com"
            className="mb-0"
          />
          {errors.email && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.email}
            </div>
          )}
        </div>
        <div className="position-relative text-start mb-2 col-md-6">
          <label htmlFor="newPassword">Password*</label>
          <input
            value={formData.password}
            type={showPassword ? "text" : "password"}
            name="newPassword"
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            onBlur={handleBlur("password")}
            placeholder="********"
            className="mb-0"
          />
          {errors.password && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.password}
            </div>
          )}
          <div
            className="eye-div-2 bg-scndry"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <VisibilityOff className="d-flex mt-5 mx-auto fs-1 text-blue" />
            ) : (
              <Visibility className="d-flex mt-5 mx-auto fs-1 text-blue" />
            )}
          </div>
        </div>
        <div className="position-relative text-start mb-2 col-md-6">
          <label htmlFor="confirmPassword" className="text-nowrap">
            Confirm Password*
          </label>
          <input
            value={formData.confirmPassword}
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            onChange={(e) =>
              setFormData({ ...formData, confirmPassword: e.target.value })
            }
            onBlur={handleBlur("confirmPassword")}
            placeholder="********"
            className="mb-0"
          />
          {errors.confirmPassword && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.confirmPassword}
            </div>
          )}
          <div
            className="eye-div-2 bg-scndry"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <VisibilityOff className="d-flex mt-5 mx-auto fs-1 text-blue" />
            ) : (
              <Visibility className="d-flex mt-5 mx-auto fs-1 text-blue" />
            )}
          </div>
        </div>
        {/* <div className="text-start col-12 mb-2">
          <label htmlFor="phone">Phone</label>
          <PhoneInput
            placeholder="XXX XXXXXXX"
            value={formData.phone}
            name="phone"
            required
            onChange={(value) => setFormData({ ...formData, phone: value })}
            onBlur={handleBlur("phone")}
            className="Icon"
            defaultCountry="US"
          />
          {errors.phone && (
            <div className="invalid-feedback d-block fw-bold">
              {errors.phone}
            </div>
          )}
        </div> */}
        {/* <div className="text-start mb-2 col-12">
          <label htmlFor="physicalAddress" className="text-nowrap">
            Physical Address
          </label>
          <input
            value={formData.physicalAddress}
            type="text"
            name="physicalAddress"
            onChange={(e) =>
              setFormData({
                ...formData,
                physicalAddress: e.target.value,
              })
            }
            onBlur={handleBlur("physicalAddress")}
            placeholder="Physical address (Optional)"
            className="mb-0"
          />
        </div> */}
        {/* <div className="col-md-6 date-picker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label htmlFor="dob" className="text-start w-100">
              Date of Birth
            </label>
            <DatePicker
              value={formData.dob}
              selected={formData.dob}
              name="dob"
              onChange={(date) =>
                setFormData({ ...formData, dob: date.format("YYYY-MM-DD") })
              }
              onBlur={handleBlur("dob")}
              className="bg-white"
              format="YYYY-MM-DD"
              views={["year", "month", "day"]}
            />
          </LocalizationProvider>
        </div> */}
        {/* <div className="form-group col-md-6 mb-3 ms-auto text-end">
          <label htmlFor="gender" className="text-start w-100">
            Gender
          </label>
          <select
            className="form-control form-control-lg MB-0"
            value={formData.gender}
            required
            id="gender"
            onChange={(e) =>
              setFormData({ ...formData, gender: e.target.value })
            }
            onBlur={handleBlur("gender")}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="others">Others</option>
          </select>
        </div> */}
        <div className="form-group">
          <button
            // type="submit"
            className="btn btn-secondary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45 mt-4"
            // className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45 mt-4"
            disabled={true}
          >
            {sendingReq ? (
              <CircularProgress color="inherit" size={22} />
            ) : (
              "Sign Up"
            )}
          </button>
          <div className="fs-12 mt-2 text-info d-block fw-bold">
            Sign-Up Currently Unavailable for General Public{" "}
            <InfoRounded onClick={() => setDisabledSignupModal(true)} />
          </div>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message ? true : errors.serverError ? true : false}
        onClose={() => {
          setMessage("");
          setErrors({});
        }}
      >
        <Alert
          severity={errors.serverError ? "warning" : message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {message || errors.serverError}
        </Alert>
      </Snackbar>
      <ModalComponent
        disabledSignupModal={disabledSignupModal}
        setDisabledSignupModal={setDisabledSignupModal}
      />
    </>
  );
}

const ModalComponent = ({ disabledSignupModal, setDisabledSignupModal }) => {
  const style_sm = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "300px",
    boxShadow: 24,
    borderRadius: "8px",
  };

  return (
    <Modal
      open={disabledSignupModal}
      onClose={() => setDisabledSignupModal(false)}
    >
      <div className="container bg-light" style={style_sm}>
        <p className="position-absolute end-0 top-0">
          <IconButton onClick={() => setDisabledSignupModal(false)}>
            <Close />
          </IconButton>
        </p>
        <p className="fs-16 fw-bold Noto_Sans">
          {" "}
          Sign-Up Currently Unavailable
        </p>
        <p className="fs-16 Noto_Sans">
          Thank you for your interest. At this time, Log-ins are restricted to a
          specific pre-registered audience. If you have any specific inquiries
          or would like more information, feel free to contact our support team
          at{" "}
          <a className="ms-1" href="mailto:support@aiforlife.ai">
            support@aiforlife.ai
          </a>
          .
        </p>
        {/* <button
          className="btn btn-secondary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45 mt-4"
          onClick={() => setDisabledSignupModal(false)}
        >
          Close
        </button> */}
      </div>
    </Modal>
  );
};
