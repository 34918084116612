import React, { useState, useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../Atoms/CustomComponets/Navbar";
import Profile from "../Routs/Profile/Index";
import Home from "../Routs/Home/Index";
import Rules from "../Routs/Rules";
import ContactUs from "../Routs/ContactUs";
import PatientDetail from "../Routs/PatientDetail/Index";
import ModalComponent from "../Atoms/CustomComponets/ModalComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "../firebase";
import { Notifications } from "@mui/icons-material";
import footerLogo from "../Assets/Images/ai4lyfLogo.svg";
import Email from "../Atoms/CustomComponets/Email";

export default function Index() {
  const location = useLocation();
  const [notification, setNotification] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openResToPat, setOpenResToPat] = useState(false);
  const [patDetail, setPatDetail] = useState([]);
  const [allNotifications, setallNotifications] = useState([]);
  const [notierrorMessage, setnotierrorMessage] = useState("");
  const [refreshTable, setrefreshTable] = useState(false);
  const [filterBy, setfilterBy] = useState("hourly");
  const [selectedDate, setselectedDate] = useState("");
  const [tourStart, setTourStart] = useState(false);

  // Functions to handle opening and closing different modals
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleNotiOpenModal = () => setOpenNotifications(true);
  const handleNotiCloseModal = () => setOpenNotifications(false);
  const handleResToPatOpenModal = () => setOpenResToPat(true);
  const handleResToPatCloseModal = () => setOpenResToPat(false);

  // Function to listen for messages and set notifications
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  // Function to format notification
  const notificationFormat = () => {
    return (
      <>
        <p className="fs-16 fw-bold Noto_Sans mb-0">
          <Notifications className="me-3 fs-1" />
          {notification?.title}
        </p>
        <p className="fs-14 Noto_Sans mb-0">{notification?.body}</p>
      </>
    );
  };

  // Display notification using toast from react-toastify
  toast(notification && notificationFormat, {
    autoClose: 2000,
    onClose: () => {
      setNotification(null);
    },
  });

  return (
    <>
      {/* Navbar component */}
      <Navbar
        setOpenNotifications={setOpenNotifications}
        openNotifications={openNotifications}
        setTourStart={setTourStart}
        tourStart={tourStart}
      />
      {/* Routes for different components */}
      <Routes>
        <Route path="previewEmail/:pat_Name" element={<Email />} />
        <Route
          path="Dashboard"
          element={
            <Home
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleCloseModal={handleCloseModal}
              handleOpenModal={handleOpenModal}
              allNotifications={allNotifications}
              setallNotifications={setallNotifications}
              notification={notification}
              notierrorMessage={notierrorMessage}
              setnotierrorMessage={setnotierrorMessage}
              setrefreshTable={setrefreshTable}
              refreshTable={refreshTable}
              setfilterBy={setfilterBy}
              setselectedDate={setselectedDate}
              setTourStart={setTourStart}
              tourStart={tourStart}
            />
          }
        />
        <Route path="Profile" element={<Profile />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route
          path="Detail/:user_id"
          element={
            <PatientDetail
              setOpenResToPat={setOpenResToPat}
              setPatDetail={setPatDetail}
              patDetail={patDetail}
              filterBy={filterBy}
              setfilterBy={setfilterBy}
              selectedDate={selectedDate}
              setselectedDate={setselectedDate}
              tourStart={tourStart}
              setTourStart={setTourStart}
            />
          }
        />
        <Route
          path="Rules"
          element={<Rules tourStart={tourStart} setTourStart={setTourStart} />}
        />
        <Route path="/*" element={<Navigate to="/Dashboard" replace />} />
      </Routes>
      {/* Modals and ToastContainer components */}
      <ModalComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        openNotifications={openNotifications}
        setOpenNotifications={setOpenNotifications}
        handleNotiOpenModal={handleNotiOpenModal}
        handleNotiCloseModal={handleNotiCloseModal}
        openResToPat={openResToPat}
        setOpenResToPat={setOpenResToPat}
        handleResToPatOpenModal={handleResToPatOpenModal}
        handleResToPatCloseModal={handleResToPatCloseModal}
        patDetail={patDetail}
        setPatDetail={setPatDetail}
        allNotifications={allNotifications}
        setrefreshTable={setrefreshTable}
        refreshTable={refreshTable}
        setfilterBy={setfilterBy}
        setselectedDate={setselectedDate}
      />
      <ToastContainer autoClose={5000} />
      <footer className="container py-2">
        <p className="Noto_Sans fs-16 fw-400 mb-0 text-center">
          <span className="text-primry fw-600 me-2">CoughLog</span>Powered by
          <img src={footerLogo} className="ms-3" />
        </p>
      </footer>
    </>
  );
}
