// import React, { useState } from "react";
import { ContactMail } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";

// function Index() {

//   return (
//     <div className="container px-0 pb-0 px-md-4">
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         open={Message ? true : ErrorMessage ? true : false}
//         onClose={() => {
//           setMessage("");
//           setErrorMessage("");
//         }}
//       >
//         <Alert
//           severity={ErrorMessage ? "warning" : Message && "success"}
//           autohideduration={3000}
//           sx={{ width: 500, fontSize: "18px" }}
//         >
//           {Message || ErrorMessage}
//         </Alert>
//       </Snackbar>
//       lksdjfnljkmnil
//     </div>
//   );
// }

// export default Index;
import { useState } from "react";

export default function Fetch() {
  const [Message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setsubject] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  function submit(e) {
    // This will prevent page refresh
    e.preventDefault();

    // replace this with your own unique endpoint URL
    fetch("https://formcarry.com/s/XXXXXXX", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: email, message: Message }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          setSubmitted(true);
        } else {
          setError(res.message);
        }
      })
      .catch((error) => setError(error));
  }

  if (error) {
    setError(error);
  }

  if (submitted) {
    setMessage("We've received your message, thank you for contacting us!");
  }

  return (
    <div className="container px-0 pb-0 px-md-4">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Message ? true : ErrorMessage ? true : false}
        onClose={() => {
          setMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : Message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {Message || ErrorMessage}
        </Alert>
      </Snackbar>
      <h1 className="p-2">
        <ContactMail className="text-primry fs-1" /> Contact Us
      </h1>
      <div className="col-md-5 mx-auto h-100 d-flex flex-column">
        <div className="my-5">
          <form onSubmit={submit}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Subject</label>
            <input
              id="subject"
              type="subject"
              value={subject}
              onChange={(e) => setsubject(e.target.value)}
              required
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={Message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <button type="submit" className="btn btn-primary fs-14 py-4 col-2">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
