import axios from "axios";

// Retrieving authentication token from localStorage
const authToken = localStorage.getItem("accessToken");
// Setting default headers for Axios requests with the authentication token
axios.defaults.headers.post["Authorization"] = `Bearer ${authToken}`;
axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
// Setting the base URL for Axios requests
// Change the URL based on the environment (development, production, etc.)
// axios.defaults.baseURL = "http://192.168.22.148:8082/";
axios.defaults.baseURL = "https://coughdairy.ai4lyf.com/";

// Account class responsible for handling authentication-related API requests
class Account {
  constructor() {
    this.result = [];
    this.user = null;
  }

  // Method for user signup
  SignUp = async (formData) => {
    try {
      const resp = await axios.post(`doc/signup`, formData);
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };

  // Method for user login
  LoginApi = (email, password, guid) => {
    const jsonData = {
      email: email,
      password: password,
      guid: guid,
    };
    const res = async () => {
      const resp = await axios
        .post(`doc/login`, jsonData)
        .catch(function (error) {
          console.log(error);
        });
      return resp;
    };
    return res();
  };

  // Method for Google login
  GoogleLoginApi = (token, guid) => {
    const jsonData = {
      id_token: token,
      guid: guid,
    };
    const res = async () => {
      const resp = await axios
        .post(`/doc/google/login`, jsonData)
        .catch(function (error) {
          console.log(error);
        });
      return resp;
    };
    return res();
  };

  // Method to request OTP for password reset (commented out alternatives included)
  ForgetOtp = async (email) => {
    try {
      const resp = await axios.post(`/doc/forget/otp?email=${email}`);
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };

  // Method for password reset
  ForgetPassword = async (formData) => {
    try {
      const resp = await axios.post(`/doc/forget/password`, formData);
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };
}
export default new Account();
