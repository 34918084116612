import React, { useState, useEffect } from "react";
import { Cancel, Rule, Save } from "@mui/icons-material";
import GetData from "../../API/GetData";
import {
  Alert,
  Chip,
  CircularProgress,
  FormHelperText,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import settings from "../../Assets/Images/Icon/settings.svg";
import Joyride from "react-joyride";

export default function Index() {
  const [severeLevel, setsevereLevel, tourStart] = useState("");
  const [RulesData, setRulesData] = useState([]);
  const [getRules, setgetRules] = useState([]);
  const [Message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ShouldFormSubmit, setShouldFormSubmit] = useState(false);
  const [modifiedRuleIndex, setModifiedRuleIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const res = GetData.GetGenRule();
    res.then((value) => {
      setRulesData(value.data);
      setgetRules(value.data);
    });
  }, [refresh]);

  const handleGeneralRules = (e) => {
    e.preventDefault();
    if (ShouldFormSubmit)
      if (modifiedRuleIndex !== null) {
        const Payload = {
          rule_id: parseInt(getRules[modifiedRuleIndex].id),
          value: parseInt(getRules[modifiedRuleIndex].value),
        };
        console.log(Payload, "Payload");
        const res = GetData.PostGenRule(Payload);
        res.then((value) => {
          setMessage(
            `Severity ${value.data.detail} for ${getRules[modifiedRuleIndex].time_span}`
          );
          setShouldFormSubmit(false);
          setRefresh(!refresh);
        });
      } else {
        setMessage("No rules have been modified.");
      }
  };

  const handleRuleChange = (index, fieldName, value) => {
    const updatedRules = [...getRules];
    updatedRules[index][fieldName] = value;
    setgetRules(updatedRules);
    setShouldFormSubmit(true);
    setModifiedRuleIndex(index);
  };

  console.log(RulesData, getRules, "tan tan tan");

  const rulesTourSteps = [
    {
      target: "#notification-rules",
      content:
        "Doctors have the ability to establish rules that automate notification systems for their patients, enhancing the overall patient experience.",
    },
  ];

  return (
    <div className="container px-0 pb-0 px-md-4">
      {/* <Joyride
        steps={rulesTourSteps}
        run={tourStart}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress={true}
        disableScrollParentFix={true}
      /> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Message ? true : ErrorMessage ? true : false}
        onClose={() => {
          setMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : Message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {Message || ErrorMessage}
        </Alert>
      </Snackbar>
      <h1 className="p-2">
        <img src={settings} /> Customize Notification Rules
      </h1>
      <div className="col-12 col-md-8 col-lg-6 mx-auto mt-2 vh100-card">
        <div>
          <div id="notification-rules" className="ticket-cards h-100 mb-3">
            <h1 className="p-2 my-2 Noto_Sans fs-20 fw-600">General Rules</h1>
            <form className="p-4 mx-auto" onSubmit={handleGeneralRules}>
              {getRules.length > 0 ? (
                getRules.map((each, index) => {
                  const isRuleModified = modifiedRuleIndex === index;
                  return (
                    <div className="row" key={each.id}>
                      <div className="d-inline-flex col-md-10 overflow-scroll">
                        <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                          Send notifications if the
                          <Chip
                            label={each.time_span}
                            variant="filled"
                            className="fs-14 text-capitalize chip-severity mx-2"
                            style={{
                              background: "#feefa",
                              color: "#1470a4",
                            }}
                          />
                          cough count is greater than
                        </p>
                        <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            placeholder="Num"
                            type="number"
                            size="small"
                            value={each.value}
                            onChange={(e) =>
                              handleRuleChange(index, "value", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        {isRuleModified && ShouldFormSubmit && (
                          <Stack direction="row" className="mt-2" spacing={2}>
                            {/* <IconButton
                              className=""
                              onClick={() => {
                                setRefresh(!refresh);
                                setShouldFormSubmit(false);
                              }}
                            > */}
                            <span
                              className="d-inline-block"
                              tabindex="0"
                              data-toggle="tooltip"
                              title="reset rule"
                              onClick={() => {
                                setRefresh(!refresh);
                                setShouldFormSubmit(false);
                              }}
                            >
                              <Cancel className="fs-1 text-danger" />
                            </span>

                            {/* </IconButton> */}
                            {/* <IconButton
                              className=""
                            > */}
                            <button
                              className="d-inline-block bg-transparent border-0 p-0"
                              tabindex="0"
                              data-toggle="tooltip"
                              title="save rule"
                              type="submit"
                            >
                              <Save className="fs-1 text-success" />
                            </button>
                            {/* </IconButton> */}
                          </Stack>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center d-flex h-100 flex-column my-5 transition-loading">
                  <div className="my-auto">
                    <CircularProgress />
                    <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                      wait while rules are loading...
                    </p>
                  </div>
                </div>
              )}
            </form>
          </div>
          {/* <div className="ticket-cards h-100 mb-3 opacity-50">
            <h1 className="p-2 my-2 Noto_Sans fs-20 fw-600">
              Rules for Particular Patient
            </h1>
            <form className="p-4 mx-auto" onSubmit={handleGeneralRules}>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in an <span className="text-warning"> Hour</span>
                </p>
              </div>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in a <span className="text-warning"> Day</span>
                </p>
              </div>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in a <span className="text-warning"> Week</span>
                </p>
              </div>
            </form>
          </div>
          <div className="ticket-cards h-100 mb-3 opacity-50">
            <h1 className="p-2 my-2 Noto_Sans fs-20 fw-600">
              Rules by Features
            </h1>
            <form className="p-4 mx-auto" onSubmit={handleGeneralRules}>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in an <span className="text-warning"> Hour</span>
                </p>
              </div>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in a <span className="text-warning"> Day</span>
                </p>
              </div>
              <div className="d-inline-flex">
                <p className="Noto_Sans fw-bold fs-16 pt-3 text-nowrap me-3">
                  Send notifications if cough count is greater than
                </p>
                <div className="form-group mx-3 pt-2 col-1 text-end numberField">
                  <TextField
                    disabled
                    id="standard-basic"
                    variant="standard"
                    placeholder="Num"
                    type="number"
                    size="small"
                  />
                </div>
                <p className="Noto_Sans fw-bold fs-16 text-nowrap pt-3 me-2">
                  in a <span className="text-warning"> Week</span>
                </p>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
}
