import React, { useState, useEffect, useRef } from "react";
import StackedBarChart from "../../Atoms/CustomComponets/StackedBarChart";
import StackedByType from "../../Atoms/CustomComponets/StackedByType";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Snackbar,
  Stack,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Details,
  SentimentVeryDissatisfied,
  Info,
} from "@mui/icons-material";
import AudiosBox from "../../Atoms/CustomComponets/AudiosBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import GetData from "../../API/GetData";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  MonthCalendar,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import No_chartsData from "../../Assets/Images/NoDataIcons/No_chartsData.svg";
import No_AudioData from "../../Assets/Images/NoDataIcons/No_AudioData.svg";
import Joyride from "react-joyride";

export default function Index(props) {
  // Destructuring props
  const {
    setOpenResToPat,
    setPatDetail,
    patDetail,
    filterBy,
    setfilterBy,
    selectedDate,
    setselectedDate,
    tourStart,
  } = props;

  // Other state variables
  const { user_id } = useParams();
  const [numItemsToShow, setNumItemsToShow] = useState();
  // const [filterBy, setfilterBy] = useState("hourly");
  const [slotBy, setslotBy] = useState("");
  const [audioData, setaudioData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  // const [selectedDate, setselectedDate] = useState("");
  const [autoselectedDate, setautoselectedDate] = useState("");
  const [intensityPlot, setIntensityPlot] = useState([]);
  const [typePlot, setTypePlot] = useState([]);
  const [dateHour, setDateHour] = useState("");
  const [IntensCoughTracked, setIntensCoughTracked] = useState("");
  const [message, setMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [sendingReq, setsendingReq] = useState(false);
  const [sendingAudioReq, setsendingAudioReq] = useState(false);

  // Logic to render AudiosBox components
  const audioBoxData =
    audioData.length > 0 &&
    audioData.map((item, index) => (
      <AudiosBox
        key={index}
        audio={item.url}
        type={item.rec_type}
        intensity={item.rec_intensity}
        date_time={item.datetime}
        id={item.rec_id}
        notes={item.doc_note}
        message={message}
        setMessage={setMessage}
        ErrorMessage={ErrorMessage}
        setErrorMessage={setErrorMessage}
        tourStart={tourStart}
      />
    ));

  // Logic to display a subset of audio boxes
  const displayedAudioBoxes =
    audioBoxData.length > 0 && audioBoxData.slice(0, numItemsToShow);

  // Function to load more audio items
  const loadMoreItems = () => {
    setIsFetching(true);
    setTimeout(() => {
      setNumItemsToShow((prevNumItems) => prevNumItems + 3);
      setIsFetching(false);
    }, 3000);
  };

  const data_type = ["rec_intensity", "rec_type"];

  // Effect for fetching user details
  useEffect(() => {
    GetData.UserDetail(user_id).then((res) => {
      res = res.data;
      setPatDetail(res);
    });
  }, []);

  // Other useEffect hooks handling data fetching and updates based on state changes

  useEffect(() => {
    setsendingAudioReq(true);
    GetData.RecordingData(user_id, dateHour).then((res) => {
      res = res.data;
      setsendingAudioReq(false);
      setaudioData(res);
    });
  }, [dateHour]);

  useEffect(() => {
    if (!selectedDate) {
      setTypePlot([]);
      setIntensityPlot([]);
      setsendingReq(true);
      data_type.forEach((data_type) => {
        GetData.TypePlotData(user_id, data_type, filterBy).then((res) => {
          res = res.data;

          if (data_type === "rec_intensity") {
            setIntensityPlot(res.data);
            setautoselectedDate(res.value);
            setIntensCoughTracked(res.cough_tracked);
            setsendingReq(false);
          } else if (data_type === "rec_type") {
            setTypePlot(res.data);
          }
        });
      });
    }
  }, [filterBy]);

  useEffect(() => {
    if (slotBy) {
      setTypePlot([]);
      setIntensityPlot([]);
      setsendingReq(true);
      data_type.forEach((data_type) => {
        GetData.TypePlotDataSlot(
          user_id,
          data_type,
          filterBy,
          slotBy,
          autoselectedDate
        ).then((res) => {
          res = res.data;

          if (data_type === "rec_intensity") {
            setIntensityPlot(res.data);
            setautoselectedDate(res.value);
            setIntensCoughTracked(res.cough_tracked);
            setslotBy("");
            setsendingReq(false);
          } else if (data_type === "rec_type") {
            setTypePlot(res.data);
            setslotBy("");
          }
        });
      });
    }
  }, [slotBy]);

  useEffect(() => {
    if (selectedDate) {
      setTypePlot([]);
      setIntensityPlot([]);
      setsendingReq(true);
      data_type.forEach((data_type) => {
        GetData.TypePlotDatato(user_id, data_type, filterBy, selectedDate).then(
          (res) => {
            res = res.data;

            if (data_type === "rec_intensity") {
              setIntensityPlot(res.data);
              setautoselectedDate(res.value);
              setIntensCoughTracked(res.cough_tracked);
              setselectedDate("");
              setsendingReq(false);
            } else if (data_type === "rec_type") {
              setTypePlot(res.data);
              setselectedDate("");
            }
          }
        );
      });
    }
  }, [selectedDate]);

  const observer = useRef();
  const lastItemRef = useRef();
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, options);
    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }
    return () => {
      observer.current.disconnect();
    };
  }, []);

  // Logic to create date format
  function fullDate(params) {
    const date = new Date(params);
    const yyyy = date.getFullYear();
    const yy = yyyy.toString();
    const y = yy.slice(2, 4);
    let mm = date.toLocaleString("default", { month: "short" });
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    return dd + "-" + mm + "-" + y;
  }

  // Array containing BarCharts data
  const BarCharts = [
    { id: 1, topHeading: "Cough Loudness", chartData: intensityPlot },
    // { id: 2, topHeading: "Cough Type", chartData: typePlot },
  ];

  // Array containing steps for Joyride
  const detailTourSteps = [
    {
      target: "#patient-details-summary",
      content:
        "Shows patient's details summary, that includes patient's name, registered date, recorded hours, No. of recorded Coughs and Severity Level of the particular Patient",
    },
    {
      target: "#graph-filters",
      content:
        "Here you can filter the graph by selecting the time span as 'Hourly', 'Weekly' and 'Daily'. You can also Use arrows to move 'To' and 'Fro' with respect to the selected filter.",
    },
    {
      target: "#graph-summary",
      content:
        "Here Doctor can visualise the summary of the recent graph, which includes total coughs in that particular span and the cough rate.",
    },
    {
      target: "#chart",
      content:
        "Cough Plot by Loudness as High and Low. Cough Plot by Type as Wet and Dry.",
    },
  ];

  return (
    <div className="container px-0 px-md-4">
      {/* Joyride component for providing a guided tour */}
      <Joyride
        steps={detailTourSteps}
        run={tourStart}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress={true}
        disableScrollParentFix={true}
      />
      {/* Patient Details section */}
      <h1 className="p-2">Patient Details</h1>
      <div className="row info-card" id="patient-details-summary">
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center brdr-right">
            <p className="text-primry fw-700 fs-18 Noto_Sans">
              {patDetail?.user_info_id ? patDetail.user_info_id : "-"}
            </p>
            <p className="fs-16">Patient No.</p>
          </div>
        </div>
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center brdr-right">
            <p className="text-primry fw-700 fs-18 Noto_Sans">
              {patDetail?.name ? patDetail.name : "-"}
            </p>
            <p className="fs-16">Patient Name</p>
          </div>
        </div>
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center brdr-right">
            <p className="text-primry fw-700 fs-18 Noto_Sans">
              {patDetail?.register ? fullDate(patDetail.register) : "-"}
            </p>
            <p className="fs-16">Registered Since</p>
          </div>
        </div>
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center brdr-right">
            <p className="text-primry fw-700 fs-18 Noto_Sans">
              {patDetail?.hour_tracked ? patDetail.hour_tracked : "0"} h
            </p>
            <p className="fs-16">Hours Recorded</p>
          </div>
        </div>
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center brdr-right">
            <p className="text-primry fw-700 fs-18 Noto_Sans">
              {patDetail?.no_cough ? patDetail.no_cough : "0"}
            </p>
            <p className="fs-16">No. of Coughs Recorded</p>
          </div>
        </div>
        <div className="col-4 col-md-2 p-3">
          <div className="d-flex flex-column text-center">
            <p className="text-primry fw-700 fs-18 Noto_Sans">-</p>
            <p className="fs-16">Severity Level</p>
          </div>
        </div>
      </div>
      {/* Graph Filters section */}
      <div className="row info-card my-4">
        <div className="row my-4" id="graph-filters">
          <div className="col-12 col-sm-6 col-md-9 text-center text-md-end my-auto">
            <Chip
              label="Hourly"
              variant={filterBy == "hourly" ? "filled" : "outlined"}
              onClick={() => setfilterBy("hourly")}
              className={`fs-16 px-3 chip-styles ${
                filterBy == "hourly" && "selected-button"
              }`}
            />
            <Chip
              label="Daily"
              variant={filterBy == "daily" ? "filled" : "outlined"}
              onClick={() => setfilterBy("daily")}
              className={`fs-16 px-3 ms-3 chip-styles ${
                filterBy == "daily" && "selected-button"
              }`}
            />
            <Chip
              label="Weekly"
              variant={filterBy == "weekly" ? "filled" : "outlined"}
              onClick={() => setfilterBy("weekly")}
              className={`fs-16 px-3 ms-3 chip-styles ${
                filterBy == "weekly" && "selected-button"
              }`}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 text-center">
            <span className="brdr-right mx-5 d-none d-md-block"></span>
            <IconButton
              aria-label="back"
              style={{
                boxShadow: "#00000066 0px 2px 10px 0px",
              }}
              onClick={() => setslotBy("prev")}
            >
              <ArrowBack />
            </IconButton>
            <span
              label="0"
              variant="text"
              className="fs-16 px-3 mx-2 text-primry fw-500"
            >
              {filterBy == "hourly"
                ? `${moment(autoselectedDate).format("MMM DD, YYYY  HH:mm a")}`
                : filterBy == "daily"
                ? `${moment(autoselectedDate).format("MMM DD ,YYYY")}`
                : `Week ${
                    moment(autoselectedDate, "YYYY-MM-DD").week() -
                    moment(autoselectedDate, "YYYY-MM-DD")
                      .startOf("month")
                      .week() +
                    1
                  } of ${moment(autoselectedDate).format("MMM, YYYY")}`}
            </span>
            <IconButton
              aria-label="forward"
              style={{
                boxShadow: "#00000066 0px 2px 10px 0px",
              }}
              onClick={() => setslotBy("next")}
            >
              <ArrowForward />
            </IconButton>
            {sendingReq ? (
              <LinearProgress className="w-25 mx-auto text-primry" />
            ) : (
              <p className="w-25 mx-auto brdr-bottom"></p>
            )}
          </div>
        </div>
        <h1
          id="graph-summary"
          className="Noto_Sans fs-18 fw-600 d-inline-flex pb-4 px-4"
        >
          <span className="text-primry me-2">{IntensCoughTracked || 0}</span>
          <span className=" mx-1 fw-600">
            coughs counted with average rate of
          </span>
          <span className="text-primry mx-2">
            {filterBy == "hourly" ? (
              <span>
                {(IntensCoughTracked / 60).toFixed(2) || 0}{" "}
                <span className="text-black">coughs/min</span>
              </span>
            ) : filterBy == "daily" ? (
              <span>
                {(IntensCoughTracked / 24).toFixed(2) || 0}{" "}
                <span className="text-black">coughs/hour</span>
              </span>
            ) : (
              filterBy == "weekly" && (
                <span>
                  {(IntensCoughTracked / (24 * 7)).toFixed(2) || 0}{" "}
                  <span className="text-black">coughs/hour</span>
                </span>
              )
            )}
          </span>
        </h1>
        {/* Graph Summary and BarCharts */}
        {BarCharts.map((each) => {
          return (
            <div key={each.id} className="position-relative">
              {sendingReq && (
                <div class="text-center d-flex h-100 flex-column absolute-center-loader transition-loading">
                  <div class="my-auto">
                    <CircularProgress />
                    <p className="fs-16 fw-500 text-center text-dark my-3">
                      Loading...
                    </p>
                  </div>
                </div>
              )}
              {!sendingReq && each.chartData.length === 0 && (
                <div class="text-center d-flex h-100 flex-column absolute-center-noData transition-loading">
                  <div class="my-auto">
                    <img src={No_chartsData} />
                    <p className="fs-16 fw-500 text-center text-dark my-3">
                      No Graph Available
                    </p>
                  </div>
                </div>
              )}
              <h1 className="p-3">{each.topHeading}</h1>
              <div className="row my-4">
                <StackedBarChart
                  filterBy={filterBy}
                  setfilterBy={setfilterBy}
                  user_id={user_id}
                  setselectedDate={setselectedDate}
                  autoselectedDate={autoselectedDate}
                  data={each.chartData}
                  fixedData={intensityPlot}
                  setaudioData={setaudioData}
                  setsendingAudioReq={setsendingAudioReq}
                  ChartNo={each.id}
                  sendingReq={sendingReq}
                />
              </div>
            </div>
          );
        })}
        {/* Cough Recordings section */}
        <div className="d-block d-md-inline-flex my-4">
          <h1 className="p-3 mb-2">Cough Recordings</h1>
          <span className="brdr-right mx-5 d-none d-md-block"></span>
          {/* Displaying date pickern with hourly format and contact patient button */}
          <div id="recording-filters" className="date-picker mt-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                views={["year", "month", "day", "hours"]}
                sx={{ width: "100%" }}
                value={dateHour}
                selected={dateHour}
                ampm={false}
                onChange={(date) => {
                  setDateHour(moment(date.$d).format("YYYY-MM-DDTHH:mm:ss"));
                }}
              />
            </LocalizationProvider>
          </div>
          <button
            className="btn btn-primary Noto_Sans fs-16 text-light ms-auto my-auto w-180 h-45"
            id="respond-patient"
            onClick={() => {
              setOpenResToPat(true);
              setPatDetail(patDetail);
            }}
          >
            Contact Patient
          </button>
        </div>
        {sendingAudioReq ? (
          <div class="text-center d-flex h-100 flex-column my-5 py-5 transition-loading">
            <div class="my-auto">
              <CircularProgress />
              <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                Loading...
              </p>
            </div>
          </div>
        ) : displayedAudioBoxes.length > 0 ? (
          <div className="row mx-auto h-450">
            {displayedAudioBoxes}
            {audioBoxData.length > numItemsToShow && (
              <div ref={lastItemRef} className="col-12 text-center">
                {isFetching && <p>Loading...</p>}
              </div>
            )}
          </div>
        ) : (
          <div class="text-center d-flex h-100 flex-column my-5 py-5 transition-loading">
            <div class="my-auto">
              <img src={No_AudioData} className="" />
              <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                No Recordings Available
              </p>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message ? true : ErrorMessage ? true : false}
        onClose={() => {
          setMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : message && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {message || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
