import axios from "axios";

// GetData class responsible for handling various API data retrieval and update methods
class GetData {
  constructor() {
    this.state = {
      open: false,
    };
  }

  // Method to fetch general rule data
  GetGenRule = () => {
    const res = async () => {
      const resp = await axios.get("/rule/get").catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });
      return resp;
    };
    return res();
  };

  // Method to update general rule data
  PostGenRule = (Payload) => {
    const res = async () => {
      const resp = await axios
        .post(`/rule/update`, Payload)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch user profile data
  GetFormData = () => {
    const res = async () => {
      const resp = await axios.get("doc/profile/get").catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });
      return resp;
    };
    return res();
  };

  // Method to update user profile data
  PostFormData = (Payload) => {
    const res = async () => {
      const resp = await axios
        .post(`doc/profile/update`, Payload)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to add Notes
  PostDocNotes = async (Payload) => {
    try {
      const resp = await axios.post(`/portal/notes/add`, Payload);
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };

  // Method to Delete Patient from Doctors Portal
  PostDeletePatient = (user_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/portal/remove/${user_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch patient data
  AllPatientsList = (page, size) => {
    const res = async () => {
      const resp = await axios
        .get(`portal/patient/get?page=${page}${size && `&size=${size}`}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch all Notifications data
  NotificationHistory = async (
    pageNo,
    searchTerm,
    severity_level,
    time_span
  ) => {
    try {
      const response = await axios.get(
        `/portal/get/notification?page=${pageNo}&size=4${
          searchTerm && `&search=${searchTerm}`
        }${severity_level && `&severity_level=${severity_level}`}${
          time_span && `&time_span=${time_span}`
        }`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.request &&
        error.response.request.statusText === "Unauthorized"
      ) {
        localStorage.clear();
        window.location.reload();
      }
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  };

  // Method to fetch all Appointmens data
  AppointmentHistory = (user_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/appointment/get?user_id=${user_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch specific patient summary data
  UserDetail = (user_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/portal/user/detail/${user_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch specific patient recording data for a specific date and hour
  RecordingData = (user_id, dateHour) => {
    const res = async () => {
      const resp = await axios
        .get(
          `/portal/recording/${user_id}${
            dateHour && `?hour_filter=${dateHour}`
          }`
        )
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch particular patients Graph data wrt to hourly, daily, weekly
  TypePlotData = (user_id, data_type, filterBy) => {
    const res = async () => {
      console.log(
        `/portal/cough/${data_type}?user_id=${user_id}&filter_by=${filterBy}`,
        "slottt2"
      );
      const resp = await axios
        .get(
          `/portal/cough/${data_type}?user_id=${user_id}&filter_by=${filterBy}`
        )
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch particular patients Graph data wrt to hourly, daily, weekly and specific date
  TypePlotDatato = (user_id, data_type, filterBy, selectedDate) => {
    const res = async () => {
      const resp = await axios
        .get(
          `/portal/cough/${data_type}?user_id=${user_id}&filter_by=${filterBy}${
            selectedDate && `&value=${selectedDate}`
          }`
        )
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to fetch particular patients Graph data wrt to hourly, daily, weekly and specific date and autoselected date
  // autoselected date is the date which is recieved from the backend wrt data
  TypePlotDataSlot = (
    user_id,
    data_type,
    filterBy,
    slotBy,
    autoselectedDate
  ) => {
    const res = async () => {
      const resp = await axios
        .get(
          `/portal/cough/${data_type}?user_id=${user_id}&filter_by=${filterBy}&filter_slot=${slotBy}${
            autoselectedDate && `&value=${autoselectedDate}`
          }`
        )
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // Method to add new patient to doctor's list (send email to patient)
  AddPatient = async (formData) => {
    try {
      const resp = await axios.post(`/portal/patient/add`, formData);
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };

  // Method to add new appointment/send message to the patient
  AddAppointment = async (formData) => {
    try {
      const resp = await axios.post(`/appointment/add`, formData);
      return resp;
    } catch (error) {
      console.error("Error while adding appointment:", error);
      throw error;
    }
  };
}
export default new GetData();
