import React from "react";
import { ArrowUpward, Notifications } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function HomeNotificationsCard(props) {
  // Destructuring props
  const {
    count,
    user_id,
    p_name,
    label_value,
    time_span,
    date,
    key,
    setfilterBy,
    setselectedDate,
    cough_threshold,
  } = props;
  // Using React Router's Navigate hook
  const history = useNavigate();

  // Function to navigate to details page and set filter and selected date
  const toDetails = () => {
    history(`/Detail/${user_id}`);
    setfilterBy(time_span);
    setselectedDate(date);
  };
  return (
    <div
      className="dash-cards mx-auto h-100 px-4 mb-3 position-relative text-dark"
      key={key}
      onClick={toDetails}
    >
      {/* Severity dot */}
      <span
        className="severity-dot"
        style={{
          backgroundColor: label_value == "severe" ? "#EB272C" : "#EB272C",
        }}
      ></span>
      <div className="row my-auto position-relative">
        <div className="col-1 my-auto">
          <Notifications className="mx-auto fs-1" />
        </div>
        {/* Notification message */}
        <div className="col-11 px-1 mx-auto">
          <p className="Noto_Sans fs-13 fw-400 px-2 my-auto">
            Patient <span className="fw-600"> {p_name}</span>, had total{" "}
            {label_value} coughs in last
            <span className="fw-600 mx-2">
              {time_span == "daily"
                ? "day"
                : time_span == "weekly"
                ? "week"
                : time_span == "hourly" && "hour"}
            </span>
            which &nbsp; is above the threshold of {cough_threshold} coughs/
            <span className="fw-600 me-2">
              {time_span == "daily"
                ? "day"
                : time_span == "weekly"
                ? "week"
                : time_span == "hourly" && "hour"}
            </span>
            you set for notifying you.
          </p>
          <p className="text-primry text-end mb-0 position-absolute bottom-0 end-0">
            ({moment(date).format("ddd, MMM D | HH:mm")})
          </p>
          {/* <p className="text- Noto_Sans fs-14 fw-400 px-3">
            Patient<span className="fw-600"> {p_name}</span> had multiple
            <span className="fw-600"> {label_value} </span>
            coughs in last
            <span className="fw-600 mx-2">
              {time_span == "daily"
                ? "day"
                : time_span == "weekly"
                ? "week"
                : time_span == "hourly" && "hour"}
            </span>
            <span className="text-primry ms-1">
              ({moment(date).format("ddd, MMM D | HH:mm")})
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
}
