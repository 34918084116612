import React from "react";
import emailimg from "../../Assets/Images/emailimg.svg";
import appStore from "../../Assets/Images/appStore.svg";
import playStore from "../../Assets/Images/playStore.svg";
import { useParams } from "react-router";

export default function () {
  // Get parameters from the URL
  const data = useParams();
  const email_body = data.pat_Name.split("-ys-")[1];
  const patient_name = data.pat_Name.split("-ys-")[0];
  const doctor_name = data.pat_Name.split("-ys-")[2];
  const subject = data.pat_Name.split("-ys-")[3];

  return (
    <div className="container h-100">
      <div
        className="col-md-6 fs-10"
        style={{
          margin: "30px auto",
          background: "white",
          borderRadius: "10px",
          padding: "50px",
        }}
      >
        <div className="row">
          <img src={emailimg} className="col-10 mx-auto" />
        </div>
        <p className="fs-24 fw-700 text-center text-primry">
          You have been invited!
        </p>
        <h3>Subject: {subject}</h3>
        <h3>Dear {patient_name},</h3>
        <p>{email_body}</p>
        <p>
          You're recommended to use CoughLog for logging cough. This AI-enabled,
          privacy-aware app lets you conveniently record and monitor your cough
          patterns and share logs with your care provider for valuable insights
          into your health.
        </p>
        <p>
          {/* App store links */}
          <div className="d-inline-flex">
            <p
              style={{ fontWeight: "400", marginBottom: "14px" }}
              className="my-auto me-3"
            >
              Application Link:
            </p>
            <img src={appStore} className="me-2" />
            <img src={playStore} />
          </div>
        </p>
        <p>
          Upon registration with this email, your profile will link to Dr.{" "}
          {doctor_name}'s account for seamless communication.
        </p>
        <p>
          Your privacy and data security are top priorities. Refer to our
          privacy policy and terms of use for details.
        </p>
        <p>
          For technical support, contact us at
          <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
            support@aiforlife.com
          </span>
          . Our usual response time is 48 hours.
        </p>
        <p>Thank you for entrusting us with your healthcare journey.</p>
        <p>CoughLog powered by AI4LYF.</p>
        {/* Website link */}
        <p style={{ fontWeight: "bold" }}>www.Coughlog.com</p>
      </div>
    </div>
  );
}
