import React, { useState, useEffect } from "react";
import HomeTicketsCard from "../../Atoms/CustomComponets/HomeTicketsCard";
import HomeNotificationsCard from "../../Atoms/CustomComponets/HomeNotificationsCard";
import HomeTableCard from "../../Atoms/CustomComponets/HomeTableCard";
import Icon from "../../Assets/Images/Icon/DashboardIcon.svg";
import NotificationsData from "../../Data/NotificationsData";
import GetData from "../../API/GetData";
import No_Notifications from "../../Assets/Images/NoDataIcons/No_Notifications.svg";
import Joyride from "react-joyride";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  FilterAltOff,
  Search,
  SentimentVeryDissatisfied,
  Today,
} from "@mui/icons-material";
import { groupBy } from "lodash";
import moment from "moment";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Index(props) {
    // Destructuring props
const {
    handleOpenModal,
    setOpenModal,
    openModal,
    setallNotifications,
    allNotifications,
    setrefreshTable,
    refreshTable,
    setfilterBy,
    setselectedDate,
    notification,
    tourStart,
  } = props;

    // State initialization
const [filteredNotifications, setFilteredNotifications] =
    useState(allNotifications);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeFilter, setTimeFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [showAllFilters, setshowAllFilters] = useState(null);
  const [pageNo, setpageNo] = useState(1);
  const [lastSearched, setlastSearched] = useState("");
  const [resetNotifications, setresetNotifications] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [previousPagesData, setpreviousPagesData] = useState([]);
  const [totalPages, settotalPages] = useState(0);

   // UseEffect for fetching data based on certain dependencies
   useEffect(() => {
    GetData.NotificationHistory(pageNo, searchTerm, severityFilter, timeFilter)
      .then((res) => {
        if (previousPagesData.length === 0) {
          setallNotifications(res.data);
        } else
          setallNotifications(Object.assign({}, previousPagesData, res.data));

        setpreviousPagesData(res.data);
        setpageNo(res.page_no);
        settotalPages(res.total_page);
        setlastSearched(searchTerm);
        setresetNotifications(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [notification, pageNo, resetNotifications]);

    // Function to handle search term input
const handleSearchWord = () => {
    setSearchLoading(true);
    setlastSearched(null);
    GetData.NotificationHistory(pageNo, searchTerm, severityFilter, timeFilter)
      .then((res) => {
        setallNotifications(res.data);
        setlastSearched(searchTerm);
        setSearchLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const dashboardTourSteps = [
    {
      target: `[data-tut="Dashboard"]`,
      content:
        "The main page of the application provides doctors with an overview of all their patients, including their health status and realtime notifications.",
    },
    {
      target: `[data-tut="Profile"]`,
      content:
        "The doctor has the capability to update their profile by including personal and professional details.",
    },
    {
      target: `[data-tut="Rules"]`,
      content:
        "You have the option to establish guidelines for severity levels in order to automate the notification process.",
    },
    {
      target: "#add-patient-button",
      content:
        "With a simple click on the 'Add Patient' button, doctors can seamlessly add new patients to their monitoring or observation system.",
    },
    {
      target: "#all-patients-list",
      content:
        "It displays a list of patients along with their comprehensive summaries. This summary comprises the patient's name, registration date, recorded hours, the number of recorded coughs, and the severity level of each individual patient. Additionally, doctors have the ability to access more details by clicking on the eye icon and to remove a patient from the list by clicking on the delete icon.",
    },
    {
      target: "#notifications-section",
      content:
        "In this section, you can view real-time notifications that are generated based on the current health status of the patients.",
    },
  ];

   // Function to filter notifications based on selected filters
   const filterNotifications = () => {
    let filtered = Object.keys(allNotifications)?.reduce((acc, date) => {
      const filteredData = allNotifications[date]?.filter((notification) => {
        const nameMatch = notification.p_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const timeMatch =
          timeFilter === "" || notification.time_span === timeFilter;
        const severityMatch =
          severityFilter === "" || notification.label_value === severityFilter;
        return nameMatch && timeMatch && severityMatch;
      });

      if (filteredData.length > 0) {
        acc[date] = filteredData;
      }

      return acc;
    }, {});

    setFilteredNotifications(filtered);
  };

   // Function to handle time filter change
   const handleTimeFilter = (e) => {
    setTimeFilter(e.target.value);
  };

  // const handleSeverityFilter = (e) => {
  //   setSeverityFilter(e.target.value);
  // };

  useEffect(() => {
    filterNotifications();
  }, [searchTerm, timeFilter, severityFilter, allNotifications]);

  const handleClose = () => {
    setshowAllFilters(null);
  };
  const open = Boolean(showAllFilters);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="container px-0 pb-0 px-md-4">
      <h1 className="p-2">
        <img src={Icon} className="me-3 fs-1 mb-1 " />
        Dashboard
      </h1>

      <div className="row mt-2">
        <div id="all-patients-list" className="col-md-8">
          <h1 className="p-2">Patients Catalogue</h1>
          <HomeTableCard
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleOpenModal={handleOpenModal}
            setrefreshTable={setrefreshTable}
            refreshTable={refreshTable}
          />
        </div>
        <div className="d-none d-md-block col-md-4">
          <h1 className="p-2">Notifications</h1>
          <div className="ticket-cards h-600 w-100 position-relative">
            <div className="row">
              <div className="col-8">
                <input
                  type="text"
                  placeholder="Name"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  className="input-height w-100 my-auto"
                />
              </div>
              <div className="col-4 py-auto d-inline-flex">
                <IconButton
                  data-toggle="tooltip"
                  title="Search"
                  onClick={() => {
                    handleSearchWord();
                  }}
                >
                  {searchLoading ? (
                    <CircularProgress color="inherit" size={22} />
                  ) : (
                    <Search className="fs-1 text-primry" />
                  )}
                </IconButton>
                <IconButton
                  data-toggle="tooltip"
                  title="Reset"
                  onClick={() => {
                    setSearchTerm("");
                    setTimeFilter("");
                    setSeverityFilter("");
                    setresetNotifications(true);
                  }}
                >
                  {resetNotifications ? (
                    <CircularProgress color="inherit" size={22} />
                  ) : (
                    <FilterAltOff className="fs-1 text-primry" />
                  )}
                </IconButton>
                <IconButton
                  data-toggle="tooltip"
                  title="More Filters"
                  onClick={(e) => setshowAllFilters(e.currentTarget)}
                >
                  <FilterListIcon className="fs-1 text-primry" />
                </IconButton>
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={showAllFilters}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <form className="filter-section form-control">
                  <FormControl sx={{ minWidth: "200px" }}>
                    <FormLabel
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      Time Span
                    </FormLabel>
                    <RadioGroup
                      // row
                      aria-label="time-span"
                      name="time-span"
                      value={timeFilter}
                      onChange={handleTimeFilter}
                    >
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value="daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="weekly"
                        control={<Radio />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="hourly"
                        control={<Radio />}
                        label="Hourly"
                      />
                    </RadioGroup>

                    {/* <FormLabel
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                          fontFamily: "Noto Sans",
                          marginTop: 2,
                        }}
                      >
                        Severity Level
                      </FormLabel>
                      <RadioGroup
                        // row
                        aria-label="severity-level"
                        name="severity-level"
                        value={severityFilter}
                        onChange={handleSeverityFilter}
                      >
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="All"
                        />
                        <FormControlLabel
                          value="high"
                          control={<Radio />}
                          label="High"
                        />
                        <FormControlLabel
                          value="low"
                          control={<Radio />}
                          label="Low"
                        />
                      </RadioGroup> */}
                  </FormControl>
                  <div className="text-end mt-3">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                        GetData.NotificationHistory(
                          pageNo,
                          searchTerm,
                          severityFilter,
                          timeFilter
                        )
                          .then((res) => {
                            setallNotifications(res.data);
                            setlastSearched(searchTerm);
                            setSearchLoading(false);
                          })
                          .catch((error) => {
                            console.error("Error fetching data:", error);
                          });
                      }}
                      className="btn btn-primary p-3 fs-14 fw-500 Noto_Sans me-2"
                    >
                      {searchLoading ? (
                        <CircularProgress color="#ffff" />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </div>
                </form>
              </Popover>
            </div>
            <div className="h-550" id="notifications-section">
              <InfiniteScroll
                dataLength={Object.keys(filteredNotifications).length}
                next={() => {
                    setpageNo(pageNo + 1);
                }}
                hasMore={totalPages !== pageNo}
                loader={
                  !searchTerm && (
                    <p className="text-center my-3">
                      <CircularProgress className="fs-2 text-primry" />
                    </p>
                  )
                }
                endMessage={
                  <p className="text-center text-primry fs-24 text-700 Noto_Sans mb-0">
                    . . .
                  </p>
                }
                scrollableTarget="notifications-section"
              >
                {Object.keys(filteredNotifications).length > 0 ? (
                  Object.keys(filteredNotifications).map((date) => (
                    <div key={date}>
                      <p className="fs-10 fw-600 text-primry">
                        {moment().format("ddd, MMM D") ===
                        moment(date).format("ddd, MMM D")
                          ? "Today"
                          : moment()
                              .subtract(1, "days")
                              .format("ddd, MMM D") ===
                            moment(date).format("ddd, MMM D")
                          ? "Yesterday"
                          : moment(date).format("ddd, MMM D")}
                      </p>
                      {filteredNotifications[date]?.map((notification) => (
                        <HomeNotificationsCard
                          key={notification.user_id}
                          user_id={notification.user_id}
                          p_name={notification.p_name}
                          count={notification.count}
                          label_value={notification.label_value}
                          time_span={notification.time_span}
                          cough_threshold={notification.cough_threshold}
                          setfilterBy={setfilterBy}
                          date={notification.notification_date}
                          setselectedDate={setselectedDate}
                        />
                      ))}
                    </div>
                  ))
                ) : (
                  <div className="text-center d-flex h-100 flex-column transition-loading mt-5">
                    <div className="my-auto">
                      <img
                        src={No_Notifications}
                        className=""
                        alt="No Notifications"
                      />
                      <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                        No Data to Display
                      </p>
                    </div>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <Joyride
        steps={dashboardTourSteps}
        run={tourStart}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress={true}
        placement="bottom"
      />
    </div>
  );
}
