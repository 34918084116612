import React, { useEffect, useState } from "react";
import Icon from "../../Assets/Images/Icon/ProfileIcon.svg";
// import ZipToAddressConverter from "../../Atoms/CustomComponets/ZipToAddressConverter";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../Atoms/CustomCss/datePickerStyles.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import GetData from "../../API/GetData";
import { Alert, Snackbar } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
// import Joyride from "react-joyride";

export default function Index() {
  const [formData, setFormData] = useState("");
  const [getForm, setgetForm] = useState("");
  const [message, setMessage] = useState(null);
  const [shouldFormSubmit, setShouldFormSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [phoneError, setPhoneError] = useState(null);

  useEffect(() => {
    const res = GetData.GetFormData();
    res.then((value) => {
      setFormData(value.data);
      setgetForm(value.data);
    });
  }, []);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const Payload = getForm;
      const res = GetData.PostFormData(Payload);
      res.then((value) => {
        setMessage(value.data.detail);
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!getForm.first_name) {
      newErrors.firstName = "First Name is required";
    }

    if (!getForm.last_name) {
      newErrors.lastName = "Last Name is required";
    }

    // if (!getForm.phone || phoneError) {
    //   newErrors.phone = "Invalid Phone Number";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCopy = (text) => {
    setMessage(`Copied:  ${localStorage.getItem("invicd")}`);
  };

  // const profileTourSteps = [
  //   {
  //     target: "#update-doctors-profile",
  //     content:
  //       "Doctor can update its profile by adding personal/professional information.",
  //   },
  // ];

  return (
    <div className="container px-0 pb-0 px-md-4">
      {/* <Joyride
        steps={profileTourSteps}
        run={true}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress={true}
      /> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={message ? true : false}
        onClose={() => setMessage("")}
      >
        <Alert
          severity="success"
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <h1 className="p-2">
        <img src={Icon} className="me-3 fs-1 mb-1" />
        My Profile
      </h1>
      <div className="row vh100-card">
        <div className="">
          {/* <ZipToAddressConverter /> */}
          <form
            className="ticket-cards row col-12 col-md-4 mx-auto h-100"
            id="update-doctors-profile"
            type="submit"
            onSubmit={handleUpdateProfile}
            autoComplete="off"
          >
            {/* Personal Information */}
            <h1 className="p-2 my-3">Personal Information</h1>
            <div className="text-start mb-1 col-md-6">
              <input
                value={getForm.first_name}
                type="text"
                required
                name="firstName"
                onBlur={validateForm}
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, first_name: e.target.value });
                }}
                placeholder="First Name"
              />
              {errors.firstName && (
                <div className="invalid-feedback d-block fw-bold">
                  {errors.firstName}
                </div>
              )}
            </div>
            <div className="text-start mb-1 col-md-6">
              <input
                value={getForm.last_name}
                type="text"
                required
                name="lastName"
                onBlur={validateForm}
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, last_name: e.target.value });
                }}
                placeholder="Last Name"
              />
              {errors.lastName && (
                <div className="invalid-feedback d-block fw-bold">
                  {errors.lastName}
                </div>
              )}
            </div>
            <div className="input-group text-start mb-1 col-12">
              <label htmlFor="invitationCode" className="w-100">Invite Id</label>
              <input
                value={getForm.invitation_code}
                type="text"
                className=""
                disabled
                name="invitation_code"
                placeholder="Invitation Code"
              />
              {/* </CopyToClipboard> */}
              {/* {getForm.invitation_code} */}
            </div>
            <div className="text-start mb-1 col-12">
              <input
                value={getForm.email}
                className=""
                type="email"
                required
                disabled
                name="email"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, email: e.target.value });
                }}
                placeholder="Email address"
              />
            </div>
            {/* <div className="text-start mb-1 col-12">
              <label htmlFor="phoneNumber">Phone</label>
              <PhoneInputWithCountrySelect
                placeholder="XXX XXXXXXX"
                defaultCountry="US"
                value={getForm.phone}
                onBlur={validateForm}
                onChange={(value) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, phone: value });
                  if (value.length > 14 || value.length < 10) {
                    setPhoneError("Invalid Phone Number");
                  } else {
                    setPhoneError(null);
                  }
                }}
                className="Icon-profile"
              />
              {phoneError && (
                <div className="invalid-feedback d-block fw-bold">
                  {phoneError}
                </div>
              )}
            </div>
            <div className="text-start mb-1 col-12">
              <input
                value={getForm.zip_code}
                type="number"
                name="zipCode"
                maxLength={11}
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, zip_code: e.target.value });
                }}
                placeholder="Zip Code"
              />
            </div>
            <div className="text-start mb-1 col-12">
              <input
                value={getForm.address}
                type="text"
                name="physicalAddress"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, address: e.target.value });
                }}
                placeholder="Physical address (Optional)"
              />
            </div>
            <div className="col-6">
              <label htmlFor="dob" className="text-start w-100">
                Date of birth
              </label>
              <DatePicker
                id="dob"
                required
                showIcon
                todayButton="Today"
                value={getForm.dob && moment(getForm.dob).format("YYYY-MM-DD")}
                isClearable
                onChange={(date) => {
                  setShouldFormSubmit(true);
                  setgetForm({
                    ...getForm,
                    dob: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                maxDate={new Date()}
                placeholderText="Date of Birth"
              />
            </div>
            <div className="form-group col-6 mb-3 ms-auto text-end">
              <label htmlFor="gender" className="text-start w-100">
                Gender
              </label>
              <select
                className="form-control form-control-lg"
                id="gender"
                required
                value={getForm.gender}
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, gender: e.target.value });
                }}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
            </div> */}
            {/* Medical License */}
            <h1 className="p-2 my-3">Medical License</h1>
            <div className="text-start mb-1">
              <input
                value={getForm.license_no}
                type="text"
                maxLength={14}
                name="licenseNo"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, license_no: e.target.value });
                }}
                placeholder="License "
              />
            </div>
            {/* <div className="text-start mb-1">
              <input
                value={getForm.issue_authority}
                type="text"
                name="issueAuthority"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, issue_authority: e.target.value });
                }}
                placeholder="Issuing Authority/State"
              />
            </div>
            <div className="col-6">
              <DatePicker
                id="validFrom"
                todayButton="Today"
                isClearable
                value={
                  getForm.valid_from &&
                  moment(getForm.valid_from).format("YYYY-MM-DD")
                }
                onChange={(date) => {
                  setShouldFormSubmit(true);
                  setgetForm({
                    ...getForm,
                    valid_from: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                maxDate={new Date()}
                placeholderText="Valid from"
              />
            </div>
            <div className="col-6">
              <DatePicker
                id="validTo"
                todayButton="Today"
                value={
                  getForm.valid_to &&
                  moment(getForm.valid_to).format("YYYY-MM-DD")
                }
                minDate={moment(getForm.valid_from).format("YYYY-MM-DD")}
                isClearable
                onChange={(date) => {
                  setShouldFormSubmit(true);
                  setgetForm({
                    ...getForm,
                    valid_to: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                placeholderText="Valid to"
              />
            </div> */}
            {/* Professional Affiliations */}
            <h1 className="p-2 my-3">Professional Affiliations</h1>
            <div className="text-start mb-1">
              <input
                value={getForm.designation}
                type="text"
                name="Designation"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, designation: e.target.value });
                }}
                placeholder="Designation/Title"
              />
            </div>
            <div className="text-start mb-1">
              <input
                value={getForm.affiliation_facility}
                type="text"
                name="FacilityName"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({
                    ...getForm,
                    affiliation_facility: e.target.value,
                  });
                }}
                placeholder="Name of Medical Facility"
              />
            </div>
            {/* Professional Experience */}
            {/* <h1 className="p-2 my-3">Professional Experience</h1>
            <div className="text-start mb-1">
              <input
                value={getForm.specialization}
                type="text"
                name="specializationIn"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, specialization: e.target.value });
                }}
                placeholder="Area of specialisation"
              />
            </div>
            <div className="text-start mb-1">
              <input
                value={getForm.experience}
                type="number"
                min={0}
                name="Experience"
                onChange={(e) => {
                  setShouldFormSubmit(true);
                  setgetForm({ ...getForm, experience: e.target.value });
                }}
                placeholder="Years of experience"
              />
            </div> */}
            <div className="row mt-5">
              {shouldFormSubmit && (
                <div className="col-md-6 mx-auto">
                  <button
                    onClick={(e) => {
                      setgetForm(formData);
                      setShouldFormSubmit(false);
                    }}
                    className="btn btn-outline-secondary Noto_Sans fs-18 col-md-4 p-3 fw-800 w-100 me-3"
                  >
                    Reset Form
                  </button>
                </div>
              )}
              <div className="col-md-6 mx-auto">
                <button
                  type="submit"
                  className="btn Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
            {/* <button
              type="submit"
              className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100"
            >
              {sendingReq ? <CircularProgress color="inherit" color="inherit" /> : "Submit"}
            </button> */}
          </form>
        </div>
      </div>
    </div>
  );
}
