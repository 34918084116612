import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorOutline, ExpandMore } from "@mui/icons-material";
import moment from "moment";
import GetData from "../../API/GetData";
import No_History from "../../Assets/Images/NoDataIcons/No_History.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function RespondToPatientTabs(props) {
  const {
    setappointmentDate,
    appointmentDate,
    appointmentDateError,
    sendingReq,
    setdocAdvice,
    docAdvice,
    handleRespondToPatient,
    user_id,
  } = props;
  const [value, setValue] = useState(0);
  const [appHistory, setappHistory] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    GetData.AppointmentHistory(user_id).then((res) => {
      res = res.data;
      setappHistory(res);
      console.log(res);
    });
  }, []);

  return (
    <div className="secondary-bg" id="top">
      <Box className="container p-0" sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="mx-auto"
          sx={{ ml: "5vw", fontSize: "16px", fontWeight: 700, width: 200 }}
        >
          <Tab
            className="tab-buttons fw-600 fs-16 Noto_Sans text-center"
            label="Compose"
          />
          <Tab
            className="tab-buttons fw-600 fs-16 Noto_Sans text-center"
            label="History"
          />
        </Tabs>
        <Box>
          <TabPanel value={value} index={0}>
            <div className="h-100 w-100">
              <form
                className="mx-auto w-100 w-md-75 mb-3"
                type="submit"
                onSubmit={handleRespondToPatient}
                autoComplete="off"
              >
                <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                  Write message for patient
                  <span className="opacity-50 ms-2">(optional)</span>
                </p>
                <div className="text-start mb-1">
                  <textarea
                    rows="4"
                    className="h-250"
                    value={docAdvice}
                    type="text"
                    name="phone"
                    onChange={(e) => setdocAdvice(e.target.value)}
                    placeholder="Please send the notification, instructions or feedback to patient here."
                  />
                </div>

                <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                  Schedule an appointment
                  <span className="opacity-50 ms-2">(optional)</span>
                </p>
                <div className="date-picker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      sx={{ width: "100%" }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      value={appointmentDate}
                      selected={appointmentDate}
                      disablePast
                      onChange={(date) => setappointmentDate(date)}
                    />
                  </LocalizationProvider>
                  {appointmentDateError && (
                    <div className="invalid-feedback d-block fw-bold fs-10">
                      {appointmentDateError}
                    </div>
                  )}
                </div>
                <div className="row mx-0 my-4">
                  <button
                    type="submit"
                    className="btn btn-primary Noto_Sans fs-16 h-32 col-3 ms-auto text-light"
                  >
                    {sendingReq ? (
                      <CircularProgress color="inherit" size={22} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="h-100 w-100 overflo-scroll">
              <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                Appointments History
              </p>
              <div className="h-450">
                {appHistory.length > 0 ? (
                  appHistory.map((each) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore className="fs-1" />}
                          // disabled={!each.msg && true}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="Noto_Sans fs-16 fw-500 mb-0 text-primry">
                            {each.schedule ? (
                              `Appointment scheduled on ${moment(
                                each.schedule
                              ).format("ddd, MMM D | HH:mm")}`
                            ) : (
                              <span className="text-primry">
                                {/* Message to patient{" "} */}
                               Message:  "{each.msg.length > 17
                                  ? `${each.msg.slice(0, 17)}..."`
                                  : each.msg}
                              </span>
                            )}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="fs-14 Noto_Sans mb-0">
                            {each.msg || (
                              <>
                                <ErrorOutline className="fs-2 text-warning" />{" "}
                                no message was sent to patient
                              </>
                            )}
                          </p>
                          <footer className="text-end opacity-50 fs-10 fw-bold mt-3 mb-0">
                            sent on{" "}
                            {moment(each.created_at).format(
                              "ddd, MMM D | HH:mm"
                            )}
                          </footer>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="text-center d-flex h-100 flex-column transition-loading">
                    <div className="my-auto">
                      <img src={No_History} className="" />
                      <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                        No History Available
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
}
